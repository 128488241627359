import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import ControllerLabel from "../../../../components/UI/ControllerLabel";
import { ActionButton, RefreshButton } from "../../../../components/UI/index";
import { AppContext } from "../../../../context/AppContext";
import ploomberAPI from "../../../../services/ploomberAPI.ts";
import HelperTooltip from "../../../../components/HelperTooltip";
import { parseErrorMessage } from "../../../../utils/utils.ts";
import StyledController from "../../../../styles/features/dashboards/controllers/Controller.Styled";
import { AccountContext } from "../../../user/Account";
import CustomDomainRecord from "./CustomDomainRecord";
import { ApplicationSettingsContext } from "../../ApplicationSettingsContext";
import CustomDomainInput from "./CustomDomainInput";

const FEATURE_ID = "customDomain";

function SetCustomDomainController({ disabled }) {
    const { projectId } = useContext(ApplicationSettingsContext);

    const { canUserAccessComponent } = useContext(AccountContext);
    const { updateSnackbarStatus } = useContext(AppContext);

    const [isConnecting, setIsConnecting] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [userRegisteredDomains, setUserRegisteredDomains] = useState([]);
    const [registeredDomains, setRegisteredDomains] = useState([]);
    const [domainToRegister, setDomainToRegister] = useState("");

    const [disableEntireComponent, setDisableEntireComponent] = useState(false);
    const [canConnect, setCanConnect] = useState(false);

    const [fetchingExistingDomains, setFetchingExistingDomains] =
        useState(true);

    const getCustomDomains = async () =>
        new Promise((resolve) => {
            ploomberAPI
                .getCustomDomains(projectId)
                .then((domains) => {
                    setRegisteredDomains(domains);
                })
                .catch((err) => {
                    updateSnackbarStatus({
                        severity: "error",
                        message: parseErrorMessage(err),
                    });
                })
                .finally(() => {
                    resolve();
                });
        });

    const getUserRegisteredDomains = async () => {
        setFetchingExistingDomains(true);
        const domains = await ploomberAPI.getCustomDomains();
        setUserRegisteredDomains(domains);
        setFetchingExistingDomains(false);
    };

    async function initialize() {
        getUserRegisteredDomains();

        const canUse = canUserAccessComponent(FEATURE_ID) && projectId;

        if (canUse) {
            setDisableEntireComponent(disabled);
            setIsRefreshing(true);
            await getCustomDomains();
            setIsRefreshing(false);
        } else {
            setDisableEntireComponent(true);
        }
    }

    useEffect(() => {
        initialize();
    }, [disabled]);

    function handleConnectCustomDomainClick() {
        setIsConnecting(true);

        ploomberAPI
            .registerNewCustomDomain(domainToRegister, projectId)
            .then(async () => {
                await getCustomDomains();

                setDomainToRegister("");
            })
            .catch((err) => {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
            })
            .finally(() => {
                setIsConnecting(false);
            });
    }

    const handleChangeDomainInput = (newValue) => {
        setDomainToRegister(newValue);
        setCanConnect(true);
    };

    const handleCustomDomainInputError = (e) => {
        setCanConnect(false);
    };

    useEffect(() => {
        if (isConnecting) {
            setCanConnect(false);
        }
    }, [isConnecting]);

    return (
        <StyledController className="Controller" id="setCustomDomainController">
            <ControllerLabel
                text="Connect a custom domain or subdomain"
                featureId={FEATURE_ID}
            />

            <Box className="ControllerItem InputWithButtonContainer">
                <Box className="InputContainer">
                    <CustomDomainInput
                        disabled={
                            isConnecting ||
                            disableEntireComponent ||
                            fetchingExistingDomains
                        }
                        domains={userRegisteredDomains}
                        onChange={handleChangeDomainInput}
                        value={domainToRegister}
                        onValidationError={handleCustomDomainInputError}
                    />
                </Box>

                <ActionButton
                    id="connectDomainButton"
                    onClick={() => {
                        handleConnectCustomDomainClick();
                    }}
                    variant="contained"
                    disabled={!canConnect || domainToRegister.length === 0}
                    disabledOptions={
                        isConnecting
                            ? {
                                  startIcon: (
                                      <CircularProgress
                                          size={10}
                                          color="neutral"
                                      />
                                  ),
                                  text: "Connecting...",
                              }
                            : {}
                    }
                >
                    Connect
                </ActionButton>
            </Box>

            <Box className="ControllerItem">
                <Box className="RegisteredDomainsContainer">
                    <h2>
                        Registered domains or subdomains (
                        {registeredDomains?.length}){" "}
                    </h2>
                    <Box className="RefreshButtonContainer">
                        <HelperTooltip text="Refresh domains">
                            <RefreshButton
                                onClick={async () => {
                                    setIsRefreshing(true);
                                    await getCustomDomains();
                                    setIsRefreshing(false);
                                }}
                            />
                        </HelperTooltip>
                    </Box>
                </Box>

                {isRefreshing ? (
                    <Box className="RefreshingContainer">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box>
                            {registeredDomains.map((domain) => (
                                <CustomDomainRecord
                                    id={domain.name.replaceAll(".", "")}
                                    domain={domain}
                                    onDomainDelete={getCustomDomains}
                                    projectId={projectId}
                                    domains={userRegisteredDomains.map(
                                        (d) => d.name
                                    )}
                                />
                            ))}
                        </Box>

                        <Box>
                            {registeredDomains.length === 0 &&
                                "No registered domains or subdomains found"}
                        </Box>
                    </>
                )}
            </Box>
        </StyledController>
    );
}

SetCustomDomainController.propTypes = {
    disabled: PropTypes.bool,
};

SetCustomDomainController.defaultProps = {
    disabled: false,
};

export default SetCustomDomainController;
