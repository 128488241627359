import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IpynbRenderer } from "react-ipynb-renderer";
import "react-ipynb-renderer/dist/styles/default.css";
import ploomberLogo from "./views/images/logo.png";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import {
    LoadState,
    NotebookPageContainer,
    NotebookPageFooter,
    NotebookPageHeader,
    NotebookPageLogo,
    NotebookPageStatusContainer,
    NotebookPageText,
} from "./NotebookPage.styled";

function NotebookPage() {
    const { notebookId } = useParams();
    const [notebookData, setNotebookData] = useState(null);
    const [currentState, setCurrentState] = useState(LoadState.LOADING);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ploomberAPI
            .downloadNotebook(notebookId)
            .then((response) => {
                setNotebookData(JSON.parse(response.notebook));
                setCurrentState(LoadState.SUCCESS);
            })
            .catch((err) => {
                // err may not have 'detail' attribute
                const errorDetail =
                    err.detail || "Some error happened in the server";
                setErrorMessage(errorDetail);
                setCurrentState(LoadState.ERROR);
            });
    }, [notebookId]);

    return (
        <div>
            <NotebookPageHeader>
                <a href="https://ploomber.io">
                    <NotebookPageLogo src={ploomberLogo} alt="Ploomber logo" />
                </a>
            </NotebookPageHeader>
            <NotebookPageText>
                <a href="https://ploomber.io">
                    Create an account to host notebooks for free.
                </a>
            </NotebookPageText>

            {(() => {
                switch (currentState) {
                    case LoadState.LOADING:
                        return (
                            <NotebookPageStatusContainer data-testid="status-container">
                                Loading...
                            </NotebookPageStatusContainer>
                        );
                    case LoadState.SUCCESS:
                        return (
                            <NotebookPageContainer>
                                <IpynbRenderer
                                    ipynb={notebookData}
                                    syntaxTheme="ghcolors"
                                />
                            </NotebookPageContainer>
                        );
                    case LoadState.ERROR:
                        return (
                            <NotebookPageStatusContainer data-testid="status-container">
                                {errorMessage}
                            </NotebookPageStatusContainer>
                        );
                    default:
                        return (
                            <NotebookPageStatusContainer data-testid="status-container">
                                An unknown error happened.
                            </NotebookPageStatusContainer>
                        );
                }
            })()}

            <NotebookPageFooter>
                This notebook is hosted in Ploomber Cloud.{" "}
                <a href="https://ploomber.io">
                    Create an account to host notebooks for free.
                </a>
            </NotebookPageFooter>
        </div>
    );
}

export default NotebookPage;
