import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import StyledStickyButton from "../../styles/components/UI/StickyButton.Styled";

function StickyButton({ onClick, children, endIcon, startIcon }) {
    return (
        <Box
            sx={{
                position: "fixed",
                zIndex: 9999999,
                bottom: 0,
                right: 0,
                padding: 2,
            }}
        >
            <Stack direction="row" spacing={2}>
                <StyledStickyButton
                    onClick={onClick}
                    variant="outlined"
                    endIcon={endIcon}
                    startIcon={startIcon}
                >
                    {children}
                </StyledStickyButton>
            </Stack>
        </Box>
    );
}

StickyButton.propTypes = {
    endIcon: PropTypes.element,
    startIcon: PropTypes.element,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.string,
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
};

StickyButton.defaultProps = {
    endIcon: undefined,
    startIcon: undefined,
};

export default StickyButton;
