import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ONBOARDING_PARAM = "onboarding";

export function captureOnboardingUrlParam() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const onboardingParam = params.get(ONBOARDING_PARAM);
        if (onboardingParam) {
            localStorage.setItem(ONBOARDING_PARAM, onboardingParam);
            params.delete(ONBOARDING_PARAM);
            navigate(`${location.pathname}?${params.toString()}`, {
                replace: true,
            });
        }
    }, [location, navigate]);
}

export function getOnboardingFramework() {
    const onboardingFramework = localStorage.getItem(ONBOARDING_PARAM);
    if (onboardingFramework) {
        return onboardingFramework.toLocaleLowerCase();
    }
    return null;
}

export function clearOnboardingFramework() {
    localStorage.removeItem(ONBOARDING_PARAM);
}
