import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PropTypes from "prop-types";
import DeleteProjectDialog from "../../components/DeleteProjectDialog";
import { AppContext } from "../../context/AppContext";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { AccountContext } from "../user/Account";
import { JobStatus } from "../../models/enum.ts";
import { ApplicationContext } from "./ApplicationProvider";

function determineButtonProperties(jobId, jobStatus, projectId, projectName) {
    const { navigate } = useContext(AppContext);

    let buttonText = "Pending";
    let isDisabled = true;
    let onClickAction;

    switch (jobStatus) {
        case "not-deployed":
            buttonText = "Deploy";
            isDisabled = false;
            onClickAction = () => {
                navigate(`/applications/create?referredProjectId=${projectId}`);
            };
            break;
        case "running":
            buttonText = "Stop Application";
            isDisabled = false;
            onClickAction = () => {
                ploomberAPI.stopJob(jobId);
                navigate(`/applications/${projectName || projectId}/${jobId}`);
            };
            break;
        case "stopping":
            buttonText = "Stop Application";
            isDisabled = true;
            break;
        case "stopped":
            buttonText = "Start Application";
            isDisabled = false;
            onClickAction = () => {
                ploomberAPI.startJob(jobId);
                navigate(`/applications/${projectName || projectId}/${jobId}`);
            };
            break;
        case "infrastructure-failed":
        case "provisioning-failed":
        case "docker-failed":
        case "failed":
            buttonText = "Failed";
            onClickAction = () => {};
            break;
        default:
            buttonText = "Pending";
            onClickAction = () => {};
    }

    return {
        buttonText,
        isDisabled,
        onClickAction,
    };
}

function ApplicationSettingsMenu({
    projectId,
    jobId,
    jobStatus,
    projectName,
    applicationUrl,
    enableAnalyticsDashboard = false,
}) {
    const { navigate } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { onApplicationDeleted } = useContext(ApplicationContext);
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const { updateSnackbarStatus } = useContext(AppContext);
    const [disabled, setDisabled] = useState();
    const [searchParams] = useSearchParams();
    const open = Boolean(anchorEl);

    const [openDeleteProjectDialog, setOpenDeleteProjectDialog] =
        useState(false);

    // Get user type for analytics button
    useEffect(() => {
        const FEATURE_ID = "viewAnalyticsReport";
        if (
            jobStatus !== JobStatus.RUNNING ||
            searchParams.get("referredProjectId")
        ) {
            setDisabled(true);
        } else {
            const canAccess = canUserAccessComponent(FEATURE_ID);
            setDisabled(!canAccess);
        }
    }, [userType, jobStatus]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const handleApplicationDeleted = () => {
        if (onApplicationDeleted) {
            onApplicationDeleted();
        }
    };

    const { buttonText, isDisabled, onClickAction } = determineButtonProperties(
        jobId,
        jobStatus,
        projectId,
        projectName
    );

    return (
        <>
            <IconButton
                style={{ marginRight: 10 }}
                onClick={handleClick}
                data-testid={`${projectId}-menu-icon`}
            >
                <MoreHorizIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                data-testid={`${projectId}-menu`}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
            >
                <MenuItem
                    data-testid={`${projectId}-stop-start-job-menu-item`}
                    onClick={onClickAction}
                    disabled={isDisabled}
                >
                    {buttonText}
                </MenuItem>

                {jobId !== "" && (
                    <MenuItem
                        data-testid={`${projectId}-redeploy-project-menu-item`}
                        onClick={() => {
                            navigate(
                                `/applications/create?referredProjectId=${projectId}`
                            );
                        }}
                    >
                        Redeploy Application
                    </MenuItem>
                )}

                {jobId !== "" && (
                    <MenuItem
                        data-testid={`${projectId}-settings-menu-item`}
                        onClick={() => {
                            navigate(
                                `/applications/${
                                    projectName ?? projectId
                                }/${jobId}/settings`
                            );
                        }}
                    >
                        Settings
                    </MenuItem>
                )}

                {enableAnalyticsDashboard && jobId !== "" && (
                    <MenuItem
                        data-testid={`${projectId}-application-analytics-menu-item`}
                        disabled={disabled}
                        onClick={() => {
                            window.open(
                                `${applicationUrl}/analytics-report`,
                                "_blank"
                            );
                        }}
                    >
                        Analytics Report
                    </MenuItem>
                )}

                <MenuItem
                    data-testid={`${projectId}-delete-project-menu-item`}
                    onClick={(event) => {
                        handleClose(event);
                        setOpenDeleteProjectDialog(true);
                    }}
                >
                    Delete Application
                </MenuItem>
            </Menu>

            <DeleteProjectDialog
                projectName={projectName}
                projectId={projectId}
                open={openDeleteProjectDialog}
                onClose={() => {
                    setOpenDeleteProjectDialog(false);
                }}
                onProjectDeleteComplete={handleApplicationDeleted}
            />
        </>
    );
}

ApplicationSettingsMenu.propTypes = {
    projectId: PropTypes.string.isRequired,
    jobId: PropTypes.string.isRequired,
    jobStatus: PropTypes.string.isRequired,
    projectName: PropTypes.string,
    applicationUrl: PropTypes.string.isRequired,
    enableAnalyticsDashboard: PropTypes.bool,
};

ApplicationSettingsMenu.defaultProps = {
    projectName: undefined,
    enableAnalyticsDashboard: false,
};

export default ApplicationSettingsMenu;
