import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AccountContext } from "../../features/user/Account";
import { AppContext } from "../../context/AppContext";

function PublicRoute({ children }) {
    const { getSession } = useContext(AccountContext);
    const { navigate } = useContext(AppContext);

    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        getSession().then((session) => {
            if (session) {
                navigate(`/`);
            } else {
                setShouldRender(true);
            }
        });
    }, []);

    if (shouldRender) {
        return children;
    }
}

PublicRoute.propTypes = {
    children: PropTypes.element.isRequired,
};

export default PublicRoute;
