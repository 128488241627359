import HTTPRequest from "./httpRequest.ts";

interface Example {
    notebook: File;
    requirements: File;
}

/**
 * Create new file from text
 *
 * @param content file content
 * @param type file type
 * @param fileName the name of the new file
 * @returns File
 */
const createFile = (content: string, type: string, fileName: string): File => {
    const blob = new Blob([content], { type });
    const file = new File([blob], fileName);

    return file;
};

/**
 * @returns example requirements.txt
 */
const getExampleRequirementsFile = async (baseUrl: string): Promise<File> => {
    const url = `${baseUrl}/requirements.txt`;
    const response = await HTTPRequest.get(url);
    const requirements = await response.text();
    const file = createFile(requirements, "text/plain", "requirements.txt");
    return Promise.resolve(file);
};

/**
 * @returns example app.ipynb
 */
const getExampleNotebookFile = async (baseUrl: string): Promise<File> => {
    const url = `${baseUrl}/app.ipynb`;
    const response = await HTTPRequest.get(url);
    const notebook = await response.json();
    const file = createFile(
        JSON.stringify(notebook),
        "application/json",
        "app.ipynb"
    );
    return Promise.resolve(file);
};

/**
 *
 * @returns app.ipynb and requirements.txt files
 */
export const getExample = async (baseUrl: string): Promise<Example> => {
    const files = await Promise.all([
        getExampleNotebookFile(baseUrl),
        getExampleRequirementsFile(baseUrl),
    ]);
    const notebook = files[0];
    const requirements = files[1];

    const example = {
        notebook,
        requirements,
    };

    return example;
};

export const getZippedExample = async (url: string): Promise<File> => {
    const response = await HTTPRequest.get(url);
    const blob = await response.blob();
    const file = new File([blob], "app.zip", { type: "application/zip" });
    return file;
};

export default {
    getExample,
    createFile,
    getZippedExample,
};
