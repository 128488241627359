import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import "./SelectOneGrid.css";
import voila from "../../assets/frameworks/voila.svg";
import streamlit from "../../assets/frameworks/streamlit.png";
import shinyR from "../../assets/frameworks/shiny-r.png";
import docker from "../../assets/frameworks/docker.svg";
import panel from "../../assets/frameworks/panel.png";
import solara from "../../assets/frameworks/solara.png";
import flask from "../../assets/frameworks/flask.png";
import chainlit from "../../assets/frameworks/chainlit.png";
import dash from "../../assets/frameworks/dash.png";
import StyledSelectOneGrid from "../../styles/components/BlockTable/SelectOneGrid.styled";
import telemetry from "../../services/telemetry.ts";
import { AppContext } from "../../context/AppContext";
import { dispatchComponentReadyEvent } from "../../utils/utils.ts";
import {
    getOnboardingFramework,
    clearOnboardingFramework,
} from "../../utils/CustomOnboarding";
import { AccountContext } from "../../features/user/Account";
import { RestrictedController } from "../../features/applications/hoc/userValidation";

const options = [
    { id: "voila", label: "Voila", image: voila },
    { id: "panel", label: "Panel", image: panel },
    { id: "solara", label: "Solara", image: solara },
    { id: "streamlit", label: "Streamlit", image: streamlit },
    { id: "shiny-r", label: "Shiny (R)", image: shinyR },
    { id: "dash", label: "Dash", image: dash },
    { id: "flask", label: "Flask", image: flask },
    { id: "chainlit", label: "Chainlit", image: chainlit },
    { id: "docker", label: "Docker", image: docker },
];

/**
 * Grid that allows selecting one option, currently used for selecting the framework
 * @returns {JSX.Element}
 */
const SelectOneGrid = function ({
    selectedOption,
    onOptionSelect,
    preSelectedId,
}) {
    const { updateSnackbarStatus } = useContext(AppContext);
    const { canUserDeployFramework } = useContext(AccountContext);

    const supportedOptions = new Set([
        "voila",
        "docker",
        "panel",
        "streamlit",
        "solara",
        "shiny-r",
        "dash",
        "flask",
        "chainlit",
    ]);

    const handleOptionSelect = (optionId, optionLabel) => {
        if (preSelectedId && preSelectedId !== optionId) {
            updateSnackbarStatus({
                message: `Please submit the new application to change the framework`,
                severity: "info",
            });
        } else {
            telemetry.log(`select-grid-${optionId}`);
            if (!canUserDeployFramework(optionId)) {
                return;
            }
            if (supportedOptions.has(optionId)) {
                onOptionSelect(optionId);
            } else {
                updateSnackbarStatus({
                    message: `${optionLabel} only available for certain customers. \
                    To get on the waitlist, send us an email: contact@ploomber.io`,
                    severity: "info",
                });
            }
        }
    };

    useEffect(() => {
        const onboardingFramework = getOnboardingFramework();
        if (supportedOptions.has(onboardingFramework)) {
            handleOptionSelect(onboardingFramework, onboardingFramework);
            clearOnboardingFramework();
        }
        dispatchComponentReadyEvent();
    }, []);

    const getPaperStyle = (option) => {
        if (!preSelectedId) {
            return {};
        }
        if (option.id !== preSelectedId) {
            return { filter: "grayscale(1)" };
        }
        return {};
    };
    const getPaperClass = (optionId) => {
        if (!canUserDeployFramework(optionId)) {
            return "disabled";
        }
        if (selectedOption === optionId) {
            return "selected";
        }
        return "not-selected";
    };
    return (
        <div className="select-one-container">
            <StyledSelectOneGrid
                container
                spacing={2}
                className="select-one-grid"
            >
                {options.map((option) => (
                    <Grid item xs={4} key={option.id} id={option.id}>
                        <Paper
                            onClick={() =>
                                handleOptionSelect(option.id, option.label)
                            }
                            className={`grid-item ${getPaperClass(option.id)}`}
                            id={`grid-${option.id}`}
                            style={getPaperStyle(option, preSelectedId)}
                            data-testid={`paper-framework-${option.id}`}
                        >
                            <img src={option.image} alt={option.label} />
                            <div className="controller-framework-label">
                                <div className="label">{option.label}</div>
                                {!canUserDeployFramework(option.id) && (
                                    <RestrictedController toolTipMsg="This option is only available for paid plans" />
                                )}
                            </div>
                        </Paper>
                    </Grid>
                ))}
            </StyledSelectOneGrid>
        </div>
    );
};

SelectOneGrid.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    preSelectedId: PropTypes.string,
};

SelectOneGrid.defaultProps = {
    preSelectedId: "",
};

export default SelectOneGrid;
