import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AccountContext } from "../../features/user/Account";
import { AppContext } from "../../context/AppContext";

function AuthorizedRoute({ children }) {
    const { getSession, authed } = useContext(AccountContext);
    const { navigate, searchParams } = useContext(AppContext);

    const [pAuthed, setPAuthed] = useState();

    useEffect(() => {
        let subscribed = true;

        if (!authed) {
            getSession(false)
                .then((session) => {
                    if (subscribed) {
                        if (session) {
                            setPAuthed(true);
                        } else {
                            setPAuthed(false);
                        }
                    }
                })
                .catch((err) => {
                    setPAuthed(false);
                });
        } else {
            setPAuthed(true);
        }

        return () => {
            subscribed = false;
        };
    }, []);

    if (pAuthed === undefined) {
        // TODO: Add a loader...
        return null;
    }

    if (!pAuthed) {
        const originalRoute = window.location.pathname;
        let returnUrl = originalRoute;

        if (searchParams) {
            returnUrl += searchParams.toString();
        }
        navigate(`/signin?returnUrl=${encodeURIComponent(returnUrl)}`);
        return "";
    }

    return children;
}

AuthorizedRoute.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AuthorizedRoute;
