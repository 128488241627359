import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField } from "@mui/material";
import HelperTooltip from "../../../HelperTooltip";
import { AppContext } from "../../../../context/AppContext";

const MAX_LABEL_LENGTH = 30;

const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

function LabelsInput({
    onLabelAdd,
    onLabelRemove,
    onEditButtonClick,
    defaultLabels,
    disabled,
    disableEvents,
}) {
    const [labels, setLabels] = useState(defaultLabels);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef();
    const [showNoLabelsText, setShowNoLabelsText] = useState(false);
    const { updateSnackbarStatus } = useContext(AppContext);
    const placeholderText =
        defaultLabels.length > 0
            ? "Add more labels..."
            : "Add labels to describe your application (press Enter or ',' to add)";

    function isValidLabel(label) {
        let isValid = false;
        let errorMessage;

        const isEmpty = label.length === 0;
        if (isEmpty) {
            errorMessage = "Label cannot be empty";
        }

        const isTooLong = label.length > MAX_LABEL_LENGTH;
        if (isTooLong) {
            errorMessage = `Label is too long (The maximum allowed length is ${MAX_LABEL_LENGTH} characters)`;
        }

        const isExists = labels.includes(label);

        if (isExists) {
            errorMessage = `Label '${label}' already exists`;
        }

        if (errorMessage) {
            updateSnackbarStatus({
                message: errorMessage,
                severity: "error",
            });
            isValid = false;
        } else {
            isValid = true;
        }

        return isValid;
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const addLabel = () => {
        if (disableEvents) {
            return;
        }
        if (inputValue.trim() !== "") {
            const newLabel = inputValue.trim();
            if (isValidLabel(newLabel)) {
                setLabels([...labels, newLabel]);
                setInputValue("");

                if (onLabelAdd) {
                    onLabelAdd(newLabel);
                }
            }
        }
    };

    const handleInputKeyDown = (e) => {
        if (e.key === "Enter" || e.key === ",") {
            e.preventDefault();
            addLabel();
        }
    };

    const handleEditButtonClick = (e, state) => {
        onEditButtonClick(e, state);
    };

    const handleChipDelete = (index) => {
        if (disableEvents) {
            return;
        }

        const labelToRemove = labels[index];
        const newLabels = [...labels];
        newLabels.splice(index, 1);
        setLabels(newLabels);

        if (onLabelRemove) {
            onLabelRemove(labelToRemove);
        }
    };

    if (onEditButtonClick) {
        useOutsideAlerter(inputRef, (e) => {
            handleEditButtonClick(e, "close");
        });
    }

    useEffect(() => {
        if (!disabled) {
            setTimeout(() => {
                document.querySelector(".MuiInputBase-input").focus();
            }, 500);
        }

        setShowNoLabelsText(
            labels.length === 0 && onEditButtonClick && disabled
        );
    }, [disabled]);

    useEffect(() => {
        setLabels(defaultLabels);
    }, [defaultLabels]);

    return (
        <div
            className={onEditButtonClick ? "WithEditButton" : ""}
            ref={inputRef}
            style={{
                display: "flex",
            }}
        >
            {showNoLabelsText && (
                <div className="NoLabelsMessage">No labels</div>
            )}

            <TextField
                id="labelsInput"
                className={disabled ? "disabled" : ""}
                disabled={disabled}
                fullWidth
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={placeholderText}
                InputProps={{
                    startAdornment: labels.length > 0 && (
                        <InputAdornment position="start">
                            {labels.map((label, index) => (
                                <Chip
                                    size="small"
                                    key={index}
                                    label={label}
                                    onDelete={(e) => handleChipDelete(index)}
                                />
                            ))}
                        </InputAdornment>
                    ),
                    endAdornment: onEditButtonClick ? (
                        <InputAdornment position="end">
                            {disabled ? (
                                <HelperTooltip text="Add or remove labels">
                                    <IconButton
                                        size="small"
                                        onClick={handleEditButtonClick}
                                        edge="end"
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </HelperTooltip>
                            ) : (
                                ""
                            )}
                        </InputAdornment>
                    ) : (
                        ""
                    ),
                }}
            />
        </div>
    );
}

LabelsInput.propTypes = {
    onLabelAdd: PropTypes.func,
    onLabelRemove: PropTypes.func,
    defaultLabels: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    onEditButtonClick: PropTypes.func,
    disableEvents: PropTypes.bool,
};

LabelsInput.defaultProps = {
    defaultLabels: [],
    onLabelAdd: (label) => {},
    onLabelRemove: (label) => {},
    disabled: false,
    onEditButtonClick: undefined,
    disableEvents: false,
};

export default LabelsInput;
