import React, {
    forwardRef,
    createContext,
    useMemo,
    useState,
    useEffect,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import ploomberAPI from "../../services/ploomberAPI.ts";

const ApplicationContext = createContext();

// function to wrap the component
const withApplicationProvider = (WrappedComponent) => {
    const ComponentWithAppProvider = forwardRef((props, ref) => {
        const params = useParams();
        const jobId = params?.jobId || undefined;
        const paramProjectId = params?.projectId || undefined;

        const [jobInfo, setJobInfo] = useState();

        const fetchJobInfo = (callback) => {
            ploomberAPI
                .getJobInfo(jobId)
                .then((data) => {
                    setJobInfo(data);

                    if (callback) {
                        callback();
                    }
                })
                .catch((err) => {
                    console.log("FAILED TO FETCH JOB INFO ", err);
                });
        };
        useEffect(() => {
            if (jobId) {
                fetchJobInfo();
            }
        }, []);

        const onUpdateJobInfo = (callback) => {
            fetchJobInfo(callback);
        };

        const applicationProviderValues = useMemo(
            () => ({
                onUpdateJobInfo,
                onApplicationDeleted: props.onApplicationDeleted,
                fetchJobInfo,
                jobInfo,
                jobId,
                paramProjectId,
            }),
            [jobInfo]
        );

        return (
            <ApplicationContext.Provider value={applicationProviderValues}>
                <WrappedComponent {...props} ref={ref} />
            </ApplicationContext.Provider>
        );
    });

    ComponentWithAppProvider.propTypes = {
        onApplicationDeleted: PropTypes.func.isRequired,
    };

    return ComponentWithAppProvider;
};

export { withApplicationProvider, ApplicationContext };
