import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css";
import React, { useEffect, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import Grid from "@mui/material/Grid";
import { Box, Button, CircularProgress } from "@mui/material";
import { AppContext } from "../../context/AppContext";
import telemetry from "../../services/telemetry.ts";
import APIKeyActionsRenderer from "./components/APIKeyActionsRenderer";
import KeyRenderer from "./components/KeyRenderer";
import InputWithCopy from "./components/InputWithCopy";
import Input from "./components/Input";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { AccountContext } from "../user/Account";
import { UserType } from "../../models/enum.ts";
import CustomDomainsGrid from "./components/CustomDomainsGrid";
import { cancelSubscription } from "../../utils/utils.ts";

function Settings() {
    const [accountDetails, setAccountDetails] = useState({
        email: "",
        apiKeys: [],
    });
    const { user, updateSnackbarStatus } = useContext(AppContext);
    const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
    const [changedRow, setChangedRow] = useState(false);
    const [userID, setUserID] = useState(false);

    const { userType } = useContext(AccountContext);
    const executeWithSpinner = async (
        apiFunc,
        args,
        successCallback,
        errorCallback
    ) => {
        setIsLoadingSpinner(true);
        try {
            const result = await apiFunc(...args);
            successCallback(result);
        } catch (error) {
            errorCallback(error);
        } finally {
            setIsLoadingSpinner(false);
        }
    };

    const handleGenerateKey = () => {
        executeWithSpinner(
            ploomberAPI.createAPIKey,
            [],
            (response) => {
                setAccountDetails({
                    ...accountDetails,
                    apiKeys: [...accountDetails.apiKeys, response],
                });
                setChangedRow(response.api_key);
                updateSnackbarStatus({
                    message: "Successfully created a new key",
                });
            },
            (_) =>
                updateSnackbarStatus({
                    message: "Failed to create a new key",
                    severity: "error",
                })
        );
    };

    const handleDelete = (keyToDelete) => {
        setChangedRow(keyToDelete);
        executeWithSpinner(
            ploomberAPI.deleteAPIKey,
            [keyToDelete],
            (_) => {
                const updatedKeys = accountDetails.apiKeys.filter(
                    (key) => key.api_key !== keyToDelete
                );
                setAccountDetails({
                    ...accountDetails,
                    apiKeys: updatedKeys,
                });
                updateSnackbarStatus({
                    message: "Successfully deleted the key",
                });
            },
            (_) =>
                updateSnackbarStatus({
                    message: "Failed to delete the key",
                    severity: "error",
                })
        );
    };

    const columnDefs = [
        {
            headerName: "API Key",
            field: "value",
            sortable: true,
            filter: true,
            flex: 3,
            resizable: true,
            cellRenderer: KeyRenderer,
        },
        {
            headerName: "Actions",
            width: 100,
            cellRenderer: APIKeyActionsRenderer,
            cellRendererParams: {
                handleDelete,
                isLoadingSpinner,
            },
            pinned: "right",
        },
    ];

    useEffect(() => {
        telemetry.log(telemetry.Events.PageView);
        executeWithSpinner(
            ploomberAPI.listAPIKeys,
            [],
            (_apiKeys) =>
                setAccountDetails({ email: user.email, apiKeys: _apiKeys }),
            (_) =>
                updateSnackbarStatus({
                    message: "Failed to fetch account details",
                    severity: "error",
                })
        );
    }, [user]);

    return !accountDetails.email ? (
        <CircularProgress size={40} />
    ) : (
        <Box
            id="accountDetails"
            sx={{
                "& .InputContainer": {
                    width: "50%",
                },
                "& .InputWithCopy": {
                    marginBottom: 3,
                    width: "100%",
                },
            }}
        >
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                    width: "100%",
                }}
            >
                {(userType === UserType.PRO.value ||
                    userType === UserType.TEAMS.value) && (
                    <Button
                        data-testid="cancelSubscriptionButton"
                        variant="outlined"
                        color="primary"
                        type="submit"
                        onClick={async () => {
                            cancelSubscription(updateSnackbarStatus);
                        }}
                    >
                        Cancel Subscription
                    </Button>
                )}
            </Box>
            {/* Flex container with gap */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h2 style={{ marginTop: 5, marginBottom: 5 }}>
                        User Profile
                    </h2>
                </Grid>
                <Grid item xs={6}>
                    {/* Empty */}
                </Grid>
                <Grid item xs={12}>
                    <InputWithCopy
                        textToCopy={accountDetails.email || ""}
                        input={
                            <Input
                                label="Email"
                                value={accountDetails.email || ""}
                                id="accountEmail"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        data-testid="revealUserID"
                        variant="outlined"
                        color="primary"
                        type="submit"
                        onClick={async () => {
                            const userSession = await ploomberAPI.getUserInfo();
                            setUserID(userSession._model.id);
                        }}
                    >
                        Click to reveal User ID
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    {/* Empty */}
                </Grid>

                {userID && (
                    <Grid item xs={12}>
                        <InputWithCopy
                            textToCopy={userID || ""}
                            input={
                                <Input
                                    label="User ID"
                                    value={userID || ""}
                                    id="showUserID"
                                />
                            }
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6} container justifyContent="space-between">
                    <h2 style={{ marginTop: 10 }}>API Keys</h2>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateKey}
                        disabled={isLoadingSpinner}
                        style={{
                            opacity: isLoadingSpinner ? 0.5 : 1,
                            margin: "auto 0px",
                        }}
                    >
                        New Key
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    {/* Empty */}
                </Grid>

                <Grid item xs={6}>
                    <div
                        className="ag-theme-material"
                        style={{ height: "100%", width: "100%" }}
                        data-testid="apiKeysGrid"
                    >
                        <AgGridReact
                            autoSizePadding={0}
                            domLayout="autoHeight"
                            columnDefs={columnDefs}
                            rowData={accountDetails.apiKeys}
                            getRowClass={(params) =>
                                params.data.api_key === changedRow
                                    ? "highlightRow"
                                    : ""
                            }
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {/* Empty */}
                </Grid>
                {/* Loading */}
                <Grid xs={6} item align="center">
                    <div style={{ width: 20 }}>
                        {isLoadingSpinner && <CircularProgress size={20} />}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {/* Empty */}
                </Grid>
            </Grid>

            <CustomDomainsGrid />
        </Box>
    );
}

export default Settings;
