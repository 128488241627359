import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { IconButton, Radio, Switch } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ploomberAPI from "../../services/ploomberAPI.ts";
import StyledApplicationLinksController from "../../styles/components/ApplicationLinksController.Styled";
import StyledApplicationLinksMenu from "../../styles/components/StyledApplicationLinksMenu.Styled";
import { AccountContext } from "../user/Account";
import { ApplicationContext } from "./ApplicationProvider";

function ApplicationLinkController({
    disabled,
    isDefault,
    isActive,
    id,
    projectId,
    onDefaultDomainUpdate,
    onDomainAvailabilityUpdate,
}) {
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [disableComponent, setDisableComponent] = useState(false);

    useEffect(() => {
        const canAccess = canUserAccessComponent("manageApplicationLinks");
        setDisableComponent(!canAccess);
    }, []);

    return (
        <div className="Row ApplicationLinkController" data-testid={id}>
            <div className="DefaultController">
                <Radio
                    disabled={disableComponent || disabled}
                    checked={isDefault}
                    onClick={() => {
                        onDefaultDomainUpdate(id, projectId);
                    }}
                />
            </div>
            <div className="URL">{id}</div>
            <div className="ActiveController">
                <Switch
                    disabled={disableComponent || disabled || isDefault}
                    checked={isActive}
                    onChange={(e) => {
                        onDomainAvailabilityUpdate(
                            id,
                            projectId,
                            e.target.checked
                        );
                    }}
                    color="info"
                />
            </div>
        </div>
    );
}

ApplicationLinkController.propTypes = {
    onDefaultDomainUpdate: PropTypes.func,
    onDomainAvailabilityUpdate: PropTypes.func,
    isDefault: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    projectId: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

ApplicationLinkController.defaultProps = {
    onDefaultDomainUpdate: () => {},
    onDomainAvailabilityUpdate: () => {},
    disabled: false,
};

function ApplicationLinksController({ defaultUrl }) {
    const { onUpdateJobInfo, jobInfo } = useContext(ApplicationContext);
    const [url, setUrl] = useState(defaultUrl);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (jobInfo) {
            setLinks(jobInfo.appLinks);
        }
    }, [jobInfo]);

    useEffect(() => {
        if (links.length > 0) {
            setUrl(links.find((link) => link.isDefault).id);
        }
    }, [links]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [disableLinksController, setDisableLinksController] = useState(false);

    const open = Boolean(anchorEl);

    const withLinksControllerLock = async (callback) => {
        setDisableLinksController(true);

        await callback();

        onUpdateJobInfo(() => {
            setDisableLinksController(false);
        });
    };

    const handleDefaultDomainUpdate = async (domain, projectId) => {
        withLinksControllerLock(async () => {
            await ploomberAPI.updateDomain(domain, projectId, {
                isDefault: true,
            });
        });
    };

    const handleDomainAvailabilityUpdate = async (
        domain,
        projectId,
        isActive
    ) => {
        withLinksControllerLock(async () => {
            await ploomberAPI.updateDomain(domain, projectId, { isActive });
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    return (
        <StyledApplicationLinksController>
            <div>
                <div className="DefaultURLContainer">{url}</div>
                <IconButton
                    data-testid="open-application-links-button"
                    onClick={handleClick}
                >
                    <SettingsIcon />
                </IconButton>
            </div>

            <StyledApplicationLinksMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
            >
                <div className="ApplicationLinksMenuContainer">
                    <div className="Row Headers">
                        <div className="DefaultController">Default</div>

                        <div className="URL">URL</div>
                        <div className="ActiveController">Active</div>
                    </div>

                    {links.map((link) => (
                        <ApplicationLinkController
                            key={link.id}
                            disabled={disableLinksController}
                            {...link}
                            onDefaultDomainUpdate={handleDefaultDomainUpdate}
                            onDomainAvailabilityUpdate={
                                handleDomainAvailabilityUpdate
                            }
                        />
                    ))}
                </div>
            </StyledApplicationLinksMenu>
        </StyledApplicationLinksController>
    );
}

ApplicationLinksController.propTypes = {
    defaultUrl: PropTypes.string.isRequired,
};
export default ApplicationLinksController;
