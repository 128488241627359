import React from "react";
import { AppContext } from "../../../context/AppContext";
import telemetry from "../../../services/telemetry.ts";
import { RegisterView } from "./views";
import JoinSlackStickyButton from "./views/components/JoinSlackStickyButton";
import { parseErrorMessage } from "../../../utils/utils.ts";

class SigninPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        telemetry.log(telemetry.Events.PageView, { includeUserDetails: false });
    }

    componentDidUpdate() {}

    render() {
        const appContext = this.context;
        const { navigateWithSearchParams, updateSnackbarStatus } = appContext;

        return (
            <div className="public-view-style">
                <JoinSlackStickyButton />

                <RegisterView
                    onSuccess={(email) => {
                        navigateWithSearchParams("/verify", {
                            state: { email },
                        });
                    }}
                    onFailure={(err) => {
                        updateSnackbarStatus({
                            message: parseErrorMessage(err),
                            severity: "error",
                        });
                    }}
                />
            </div>
        );
    }
}

SigninPage.contextType = AppContext;

export default SigninPage;
