import React, { useContext } from "react";
import { DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { AccountContext } from "../../features/user/Account";
import StyledPricingsDialog from "../../styles/components/Pricings/PricingsDialog.Styled";
import PricingPlan from "./PricingPlan";

function PricingsDialog({ open, onClose, pricingPlans }) {
    const { userType } = useContext(AccountContext);

    return (
        <StyledPricingsDialog
            className="PricingDialog"
            fullScreen
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <IconButton className="CloseIcon">
                    <CloseIcon onClick={onClose} />
                </IconButton>
            </DialogTitle>

            <h1 className="PageTitle">Choose your pricing plan</h1>
            <div className="PlansContainer">
                {pricingPlans.map((plan, i) => (
                    <PricingPlan {...plan} selected={plan.id === userType} />
                ))}
            </div>
        </StyledPricingsDialog>
    );
}

PricingsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    pricingPlans: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            priceHTML: PropTypes.string,
            features: PropTypes.arrayOf(PropTypes.string),
            primaryAction: PropTypes.shape({
                title: PropTypes.string,
                onClick: PropTypes.func,
            }),
        })
    ).isRequired,
};

PricingsDialog.defaultProps = {
    open: false,
    onClose: () => {},
};

export default PricingsDialog;
