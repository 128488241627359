import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../components/UI/Form/inputs/Select";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import { AccountContext } from "../../user/Account";
import telemetry from "../../../services/telemetry.ts";
import SelectController from "./SelectHardwareController";

const DEFAULT_OPTION = `No GPUs`;

function SelectGPUController({ gpuOptions, onChange, featureId, value }) {
    return (
        <div className="Controller" data-testid="select-gpu">
            <SelectController
                optionsData={gpuOptions}
                onChange={onChange}
                featureId={featureId}
                value={value}
                labelText="Number of GPUs"
                unitText="GPUs"
            />
        </div>
    );
}

SelectGPUController.propTypes = {
    gpuOptions: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        )
    ),
    onChange: PropTypes.func.isRequired,
    featureId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

SelectGPUController.defaultProps = {
    gpuOptions: ["0", "1", "2"],
};

export default SelectGPUController;
