import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Button from "@mui/material/Button";
import { AppContext } from "../../context/AppContext";
import RefreshButton from "../../components/UI/RefreshButton";

import telemetry from "../../services/telemetry.ts";
import ploomberAPI from "../../services/ploomberAPI.ts";
import Grid from "../../components/UI/Grid";
import HelperTooltip from "../../components/HelperTooltip";
import { parseErrorMessage } from "../../utils/utils.ts";

import { ActionButton } from "../../components/UI";

function NotebookRenderer({ data, colDef }) {
    if (!data) {
        return null;
    }
    const { field } = colDef;

    // Render different content based on the column's field
    switch (field) {
        case "id":
            return data.isLoading ? (
                <CircularProgress size={20} />
            ) : (
                <span>{data.id}</span>
            );
        case "created":
            return <span>{data.created}</span>;
        case "link":
            return (
                <div className="LinkCell">
                    {!data.isLoading && (
                        <a
                            href={`/notebooks/${data.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ActionButton
                                variant="text"
                                startIcon={<LaunchIcon />}
                            >
                                open
                            </ActionButton>
                        </a>
                    )}
                </div>
            );
        default:
            return null;
    }
}

NotebookRenderer.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        created: PropTypes.string,
        isLoading: PropTypes.bool,
    }),
    colDef: PropTypes.shape({
        field: PropTypes.string.isRequired, // Assuming field is a required string
    }).isRequired,
};

NotebookRenderer.defaultProps = {
    data: null,
};

// defaultPagesToFetch determines how many pages are queried whenever the Grid Component requests more server side data
// Balance between server side payload size vs UI responsive, adjust accordingly
const defaultPageSize = 10;
const defaultPagesToFetch = 1;
const cacheBlockSize = defaultPageSize * defaultPagesToFetch;

function Notebooks() {
    const { navigate, updateSnackbarStatus } = useContext(AppContext);
    const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
    const [enableCellTextSelection, setEnableCellTextSelection] =
        useState(true);
    const [firstPageData, setFirstPageData] = useState([]);

    const columnsDefs = [
        {
            field: "id",
            headerName: "Notebook ID",
            cellRenderer: NotebookRenderer,
            suppressMenu: true,
            flex: 1,
        },
        {
            field: "created",
            headerName: "Created",
            cellRenderer: NotebookRenderer,
            suppressMenu: true,
            flex: 1,
        },
        {
            field: "link",
            headerName: "Link",
            cellRenderer: NotebookRenderer,
            suppressMenu: true,
            flex: 1,
        },
    ];

    const gridRef = useRef(null);

    const containerStyle = useMemo(
        () => ({ width: "100%", height: "100%", marginBottom: 70 }),
        []
    );

    const gridStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

    const fetchFirstPageData = () => {
        ploomberAPI
            .getNotebooks(cacheBlockSize, 0)
            .then((_notebooks) => {
                // Update the jobCount state variable
                setFirstPageData(_notebooks);
                setInitialFetchCompleted(true);
            })
            .catch((err) => {
                updateSnackbarStatus({
                    message: parseErrorMessage(err),
                    severity: "error",
                });
            });
    };

    useEffect(() => {
        fetchFirstPageData();
        telemetry.log(telemetry.Events.PageView);
    }, []);

    const dataSource = {
        getRows: (params) => {
            const currentPage = Math.floor(params.startRow / cacheBlockSize);
            const isFirstPage = currentPage === 0;

            if (isFirstPage && initialFetchCompleted) {
                params.successCallback(
                    firstPageData.notebooks,
                    firstPageData.count
                );
                gridRef.current.api.setDomLayout("autoHeight");
                return;
            }

            // Render a Loader Row
            params.successCallback([{ isLoading: true }], firstPageData.count);

            // Calculate the current page based on the requested rows
            ploomberAPI
                .getNotebooks(cacheBlockSize, currentPage)
                .then((_jobs) => {
                    params.successCallback(
                        _jobs.notebooks,
                        firstPageData.count
                    );

                    if (_jobs.length > 0) {
                        gridRef.current?.api?.hideOverlay();
                    }
                })
                .catch((err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                    params.failCallback();
                })
                .finally(() => {
                    gridRef.current.api.setDomLayout("autoHeight");
                });
        },
    };

    return (
        <div data-testid="notebooks-1">
            <div style={containerStyle}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 id="notebooksHeader">Notebooks</h2>
                        <HelperTooltip text="Share new notebook">
                            <Button
                                data-testid="share-new-notebook"
                                style={{ margin: "auto 20px" }}
                                component="a"
                                href="https://docs.cloud.ploomber.io/en/latest/features/notebook-sharing.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="contained"
                                color="primary"
                                id="shareNotebookButton"
                            >
                                New
                            </Button>
                        </HelperTooltip>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <HelperTooltip text="Fetch notebooks">
                            <RefreshButton
                                onClick={() => {
                                    telemetry.log(
                                        `${telemetry.Pages.Notebooks}-RefreshClick`
                                    );
                                    setInitialFetchCompleted(false);
                                    fetchFirstPageData();
                                }}
                            />
                        </HelperTooltip>
                    </div>
                </div>
                {!initialFetchCompleted ? (
                    <CircularProgress />
                ) : (
                    <Grid
                        style={gridStyle}
                        ref={gridRef}
                        columnDefs={columnsDefs}
                        onGridReady={(params) => {
                            params.api.setDatasource(dataSource);
                        }}
                        suppressPaginationPanel={
                            firstPageData.count < defaultPageSize
                        }
                        paginationPageSize={defaultPageSize}
                        cacheBlockSize={cacheBlockSize}
                        frameworkComponents={{
                            notebookRenderer: NotebookRenderer,
                        }}
                        enableCellTextSelection={enableCellTextSelection}
                    />
                )}
            </div>
        </div>
    );
}

export default Notebooks;
