import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledJobSummary = styled(Box)(({ theme }) => ({
    ".JobInfoContainer": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "inherit",
    },
    ".ActionButton": {
        marginRight: theme.spacing(1),
    },
    "& .InputWithCopy": {
        justifyContent: "flex-end",
        fontSize: theme.fonts.size.medium,
    },
    ".HardWareSettings": {
        // to align URL horizontally
        marginTop: "-39px",
        fontSize: theme.fonts.size.medium,
    },
    ".FrameworkContainer": {
        display: "flex",
        alignItems: "center",
        "& .FrameworkImage": {
            marginRight: 5,
        },
    },
    ".FrameworkImage": {
        width: "auto",
        height: 25,
    },
    ".FrameworkLabelContainer": {
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
}));

export default StyledJobSummary;
