import React, { useContext, useRef, useState } from "react";
import "./css/style.css";
import "./css/register.css";
import PropTypes from "prop-types";
import ploomberLogo from "./images/logo.svg";
import { AccountContext } from "../../../../features/user/Account";
import SocialLogin from "../../../../components/SocialLogin";
import { togglePasswordVisibility } from "../../../../utils/uiUtils.ts";

const specialChars = [
    "^",
    "$",
    "*",
    ".",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "?",
    "-",
    '"',
    "!",
    "@",
    "#",
    "%",
    "&",
    "/",
    "\\",
    ",",
    ">",
    "<",
    "'",
    ":",
    ";",
    "|",
    "_",
    "~",
    "`",
    "+",
    "=",
];

function RegisterView({ onSuccess, onFailure }) {
    const accountContext = useContext(AccountContext);
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const passwordConfInputRef = useRef(null);

    // the message displayed below the submit button
    const [footerMessage, setFooterMessage] = useState("");

    const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [userTypedPassword, setUserTypedPassword] = useState(false);

    const handlePasswordConfirmChange = (e) => {
        const enteredPassword = passwordInputRef.current.value;
        const passwordConfirmation = passwordConfInputRef.current.value;
        setPasswordsMatch(enteredPassword === passwordConfirmation);
    };

    // Function to handle password input changes and perform validations
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        // Update password validations based on the new password
        setPasswordValidations({
            length: newPassword.length >= 8,
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            number: /\d/.test(newPassword),
            specialChar: specialChars.some((char) =>
                newPassword.includes(char)
            ),
        });

        setUserTypedPassword(true);
        handlePasswordConfirmChange(e);
    };

    const handleSubmitRegister = (e) => {
        e.preventDefault();

        const passwordIsValid = Object.values(passwordValidations).every(
            (validation) => validation === true
        );

        if (!passwordIsValid) {
            setFooterMessage("Password does not meet the requirements.");
        } else if (!passwordsMatch) {
            setFooterMessage("Passwords do not match.");
        } else {
            const { signUp } = accountContext;

            const email = emailInputRef.current.value;
            const enteredPassword = passwordInputRef.current.value;

            setFooterMessage("Creating account...");

            signUp(email, enteredPassword)
                .then(() => {
                    onSuccess(email);
                })
                .catch((err) => {
                    onFailure(err);
                });
        }
    };

    return (
        <section className="main-content">
            <div className="login-card">
                <div className="main-title">
                    <a href="https://ploomber.io">
                        <img
                            className="logo"
                            src={ploomberLogo}
                            alt="Ploomber logo"
                        />
                    </a>
                </div>
                <h1 className="form-header playfair-font">Welcome</h1>
                <p className="disclaimer">
                    By signing up you agree with our
                    <a
                        href="https://ploomber.io/terms-and-conditions"
                        style={{ marginLeft: 3, marginRight: 3 }}
                    >
                        terms of service
                    </a>
                    and
                    <a
                        href="https://ploomber.io/terms-and-conditions/#Privacy%20Policy"
                        style={{ marginLeft: 3 }}
                    >
                        privacy policy
                    </a>
                    .
                </p>

                {/* Start of 3rd party authenticators */}
                <SocialLogin onSuccess={onSuccess} onFailure={onFailure} />

                <div className="or-legend">
                    <div className="vertical-line" />
                    <span className="or-text">or</span>
                    <div className="vertical-line" />
                </div>

                {/* End of 3rd party authenticators  */}
                <form id="signinForm" onSubmit={handleSubmitRegister}>
                    <div className="input-group">
                        <input
                            ref={emailInputRef}
                            data-testid="email"
                            type="email"
                            id="emailInputSignin"
                            className="input-email"
                            placeholder="Email address"
                            autoComplete="on"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            ref={passwordInputRef}
                            data-testid="password"
                            type="password"
                            className="input-email"
                            placeholder="Password"
                            id="passwordInputSignin"
                            onChange={handlePasswordChange}
                            required
                        />
                        <i
                            className="far fa-eye"
                            id="toggle2Password"
                            role="button"
                            onKeyPress={(e) => {}}
                            tabIndex="0"
                            onClick={(e) => {
                                togglePasswordVisibility(passwordInputRef);
                            }}
                            aria-label="Toggle password"
                        />
                    </div>

                    {userTypedPassword && (
                        <div>
                            {/* Password validations feedback */}
                            <ul className="password-validations">
                                {/* Add classes based on validation status */}
                                <li
                                    id="pass-length"
                                    className={
                                        passwordValidations.length
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    Minimum 8 characters
                                </li>
                                <li
                                    id="pass-upper"
                                    className={
                                        passwordValidations.uppercase
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    At least one uppercase letter
                                </li>
                                <li
                                    id="pass-lower"
                                    className={
                                        passwordValidations.lowercase
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    At least one lowercase letter
                                </li>
                                <li
                                    id="pass-number"
                                    className={
                                        passwordValidations.number
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    At least one number
                                </li>
                                <li
                                    id="pass-special"
                                    className={
                                        passwordValidations.specialChar
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    At least one special character $
                                    {specialChars.join("")}
                                </li>
                            </ul>
                        </div>
                    )}

                    <div className="input-group">
                        <input
                            ref={passwordConfInputRef}
                            data-testid="confirmPassword"
                            type="password"
                            id="passwordConfirmInputSignin"
                            className="input-email"
                            placeholder="Confirm Password"
                            onChange={handlePasswordConfirmChange}
                            required
                        />

                        {userTypedPassword && (
                            <ul className="password-validations">
                                <li
                                    id="pass-match"
                                    className={
                                        passwordsMatch ? "valid" : "invalid"
                                    }
                                >
                                    {passwordsMatch
                                        ? "Passwords match"
                                        : "Passwords do not match"}
                                </li>
                            </ul>
                        )}

                        <i
                            className="far fa-eye"
                            id="toggle2Password"
                            role="button"
                            onKeyPress={(e) => {}}
                            tabIndex="0"
                            onClick={(e) => {
                                togglePasswordVisibility(passwordConfInputRef);
                            }}
                            aria-label="Toggle password"
                        />
                    </div>

                    <input
                        data-testid="submit"
                        type="submit"
                        className="btn-next"
                        value="Register"
                    />

                    <div className="password-validations">
                        {footerMessage && (
                            <p
                                id="footer-message-submit"
                                className="error-message"
                            >
                                {footerMessage}
                            </p>
                        )}
                    </div>

                    <p className="register-redirect">
                        Already have an account?
                        <a href="/signin"> Sign in now!</a>
                    </p>
                </form>
            </div>
        </section>
    );
}

RegisterView.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default RegisterView;
