import { styled } from "@mui/material/styles";

const StyledAuthenticationContainer = styled("div")`
    gap: 50px;
    width: 100%;
    display: inline-flex;
    margin-top: 15px;
`;

export default StyledAuthenticationContainer;
