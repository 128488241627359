import React from "react";
import PropTypes from "prop-types";
import * as uuid from "uuid";
import StyledActionButton from "../../styles/components/UI/ActionButton.Styled";

function ActionButton({
    children,
    variant,
    color,
    disabled,
    onClick,
    hide,
    testid,
    "data-testid": dataTestId,
    style,
    startIcon,
    endIcon,
    disabledOptions,
    className,
    id,
    size,
}) {
    if (hide) {
        return "";
    }

    return (
        <StyledActionButton
            id={id}
            className={`ActionButton ${className}`}
            data-testid={dataTestId || testid || id}
            size={size}
            disabled={disabled}
            variant={variant}
            color={color}
            onClick={onClick}
            style={style}
            startIcon={(disabled && disabledOptions?.startIcon) || startIcon}
            endIcon={(disabled && disabledOptions?.endIcon) || endIcon}
        >
            {(disabled && disabledOptions?.text) || children}
        </StyledActionButton>
    );
}

ActionButton.propTypes = {
    children: PropTypes.string.isRequired,
    color: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    hide: PropTypes.bool,
    variant: PropTypes.string,
    testid: PropTypes.string,
    "data-testid": PropTypes.string,
    style: PropTypes.shape({}),
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    disabledOptions: PropTypes.shape({
        startIcon: PropTypes.string,
        endIcon: PropTypes.string,
        text: PropTypes.string,
    }),
    className: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.string,
};

ActionButton.defaultProps = {
    hide: false,
    disabled: false,
    variant: "outlined",
    color: "primary",
    onClick: () => {},
    testid: undefined,
    "data-testid": "",
    style: {},
    startIcon: undefined,
    endIcon: undefined,
    disabledOptions: {},
    className: "",
    id: uuid.v4(),
    size: "medium",
};

export default ActionButton;
