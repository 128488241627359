/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as uuid from "uuid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Terminal from "../../../components/Terminal";
import StyledLogsTabs from "../../../styles/components/features/applications/components/LogsTabs.Styled";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function LogsTabs({ logs, id, logsIdToShow }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let index = 0;
        if (logsIdToShow) {
            const logsIndex = logs.findIndex((l) => l.id === logsIdToShow);
            if (logsIndex > -1) {
                index = logsIndex;
            }
        }

        setValue(index);
    }, [logsIdToShow]);

    return (
        <StyledLogsTabs id={id} data-testid={`logs-tabs-${id}`}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange}>
                    {logs.map((log) => (
                        <Tab
                            key={log.id}
                            label={log.title}
                            data-testid={`${log.id}-tab`}
                            sx={{
                                "&.Mui-selected": {
                                    fontWeight: "bold",
                                },
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            {logs.map((log, i) => (
                <TabPanel key={log.title} value={value} index={i}>
                    <h3>{log.title}</h3>
                    <Terminal
                        text={log.logs}
                        splitTextFunction={log.splitTextFunction}
                        id={log.id}
                        placeholder={log.placeholder}
                    />
                </TabPanel>
            ))}
        </StyledLogsTabs>
    );
}

export default LogsTabs;

LogsTabs.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.shape).isRequired,
    id: PropTypes.string,
    logsIdToShow: PropTypes.string,
};

LogsTabs.defaultProps = {
    id: uuid.v4(),
    logsIdToShow: undefined,
};
