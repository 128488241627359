import React, { useRef, useState, useEffect, useContext } from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    Link,
} from "@mui/material";
import PropTypes from "prop-types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import UserValidation from "../hoc/userValidation";
import { AppContext } from "../../../context/AppContext";
import StyledAuthenticationContainer from "../../../styles/features/applications/AuthenticationContainer.Styled";

const MIN_USERNAME_LEN = 3;
const MIN_PASSWORD_LEN = 4;

function AuthenticationController({
    disabled,
    onSwitchChange,
    className,
    featureId,
    authEnabled,
    onValidationChange,
    onCredentialsUpdate,
    onToggle,
}) {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [credentialsValid, setCredentialsValid] = useState(false);
    const [usernamePlaceholder, setUsernamePlaceholder] = useState("");
    const [passwordPlaceholder, setPasswordPlaceholder] = useState("");
    const [myUserName, setMyUsername] = useState("");
    const [myPassword, setMyPassword] = useState("");
    const [isValidUsername, setIsValidUsername] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const { updateSnackbarStatus } = useContext(AppContext);

    const passwordRef = useRef(null);
    const usernameRef = useRef(null);

    const usernameValidator = (username) => {
        let errorMessage;
        const spacesPattern = /\s/;

        if (username.length === 0) {
            errorMessage = `username is empty`;
            setUsernameError(true);
        } else if (username.length > 0 && username.length < MIN_USERNAME_LEN) {
            errorMessage = `username is too short (The minimum allowed length is ${MIN_USERNAME_LEN} characters)`;
            setUsernameError(true);
        } else if (spacesPattern.test(username)) {
            errorMessage = `username shouldn't include spaces`;
            setUsernameError(true);
        }

        if (errorMessage) {
            updateSnackbarStatus({
                message: errorMessage,
                severity: "error",
            });
            setIsValidUsername(false);
        } else {
            setIsValidUsername(true);
            setUsernameError(false);
            onValidationChange(true);
        }
    };

    const passwordValidator = (password) => {
        let errorMessage;
        const spacesPattern = /\s/;
        if (password.length === 0) {
            errorMessage = `password is empty`;
            setPasswordError(true);
        } else if (password.length > 0 && password.length < MIN_PASSWORD_LEN) {
            errorMessage = `password is too short (The minimum allowed length is ${MIN_PASSWORD_LEN} characters)`;
            setPasswordError(true);
        } else if (spacesPattern.test(password)) {
            errorMessage = `password shouldn't include spaces`;
            setPasswordError(true);
        }

        if (errorMessage) {
            updateSnackbarStatus({
                message: errorMessage,
                severity: "error",
            });
            setIsValidPassword(false);
        } else {
            setIsValidPassword(true);
            setPasswordError(false);
            onValidationChange(true);
        }
    };

    useEffect(() => {
        if (usernameRef.current !== null && passwordRef.current !== null) {
            const username = usernameRef.current.value;
            const password = passwordRef.current.value;
            const credentialsState = { isValid: false, isPlaceholder: false };
            // Check if the inputs match the placeholders or are empty
            if (
                (username === usernamePlaceholder || username === "") &&
                (password === passwordPlaceholder || password === "")
            ) {
                // If placeholders are unchanged or fields are empty, send 'none' for authentication
                credentialsState.isPlaceholder = true;
                onCredentialsUpdate(null, credentialsState);
                if (authEnabled) {
                    setCredentialsValid(true);
                    onValidationChange(true);
                } else {
                    setCredentialsValid(false);
                    onValidationChange(false);
                }
            } else {
                // Otherwise, send the actual values
                const areValid = isValidUsername && isValidPassword;
                credentialsState.isValid = areValid;
                if (areValid) {
                    onCredentialsUpdate(
                        { username, password },
                        credentialsState
                    );
                    setUsernameError(false);
                    setPasswordError(false);
                    setCredentialsValid(true);
                    onValidationChange(true);
                } else {
                    setCredentialsValid(false);
                    onValidationChange(false);
                    onCredentialsUpdate(null, credentialsState);
                }
            }
        }
    }, [isValidUsername, isValidPassword]);

    useEffect(() => {
        setIsToggleOn(authEnabled);
        onToggle(authEnabled);
    }, [authEnabled]);

    useEffect(() => {
        if (authEnabled) {
            // Set placeholders to indicate previous values if auth was enabled
            setUsernamePlaceholder("●●●●●●");
            setPasswordPlaceholder("●●●●●●");
            setCredentialsValid(true);
            onValidationChange(true);
            setPasswordError(false);
            setUsernameError(false);
            setIsValidUsername(true);
            setIsValidPassword(true);
        } else {
            // Clear placeholders if auth is not enabled
            setUsernamePlaceholder("");
            setPasswordPlaceholder("");
        }
    }, [authEnabled, isToggleOn]);

    const handleSwitchChange = (e) => {
        const isChecked = e.target.checked;
        setIsToggleOn(isChecked);
        onToggle(isChecked);

        if (isChecked) {
            // Set placeholders back to indicate previous values if needed
            setUsernamePlaceholder(authEnabled ? "Previous Username" : ""); // Adjust as necessary
            setPasswordPlaceholder(authEnabled ? "Previous Password" : ""); // Adjust as necessary
            onValidationChange(authEnabled);
            if (myUserName && myPassword) {
                usernameValidator(myUserName);
                passwordValidator(myPassword);
            }
        } else {
            // Clear placeholders when turning off the switch
            setUsernamePlaceholder("");
            setPasswordPlaceholder("");
            onCredentialsUpdate(null);
            onValidationChange(true);
        }
    };

    return (
        <FormControl
            feature-id={featureId}
            className={className}
            data-testid="set-auth"
            sx={{
                width: "100%",
                "& .Mui-disabled": {
                    backgroundColor: "#9e9e9e",
                },
                "& label": {
                    fontWeight: "600",
                    color: "#000",
                    marginBottom: "5px",
                },
            }}
        >
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                id="toggle-password-protection"
                                checked={isToggleOn}
                                onChange={(e) => {
                                    setIsToggleOn(e.target.checked);
                                    handleSwitchChange(e);
                                }}
                                color="info"
                            />
                        }
                        label="Enable password protection"
                        labelPlacement="start"
                        sx={{
                            margin: 0,
                            width: "fit-content",
                        }}
                    />
                </FormGroup>
            </FormControl>

            {isToggleOn && (
                <StyledAuthenticationContainer>
                    <FormControl sx={{ width: "50%" }} variant="outlined">
                        <InputLabel
                            sx={{
                                "-webkit-transform": "inherit",
                                top: -20,
                            }}
                            htmlFor="username"
                            shrink
                        >
                            Username
                        </InputLabel>
                        <OutlinedInput
                            id="username"
                            type="text"
                            error={usernameError}
                            disabled={!isToggleOn}
                            onChange={(e) => {
                                setMyUsername(e.target.value);
                            }}
                            inputProps={{
                                ref: usernameRef,
                            }}
                            onBlur={(e) =>
                                usernameValidator(usernameRef.current.value)
                            }
                            placeholder={usernamePlaceholder}
                            autoComplete="off"
                            value={myUserName}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "50%" }} variant="outlined">
                        <InputLabel
                            sx={{
                                "-webkit-transform": "inherit",
                                top: -20,
                            }}
                            htmlFor="password"
                            shrink
                        >
                            Password
                        </InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            error={passwordError}
                            disabled={!isToggleOn}
                            onChange={(e) => {
                                setMyPassword(e.target.value);
                            }}
                            inputProps={{
                                ref: passwordRef,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onBlur={(e) =>
                                passwordValidator(passwordRef.current.value)
                            }
                            placeholder={passwordPlaceholder} // Dynamic placeholder based on state
                            autocomplete="off"
                            value={myPassword}
                        />
                    </FormControl>
                </StyledAuthenticationContainer>
            )}

            <FormLabel
                data-testid="auth0-advertisement"
                sx={{
                    "margin-top": "10px",
                }}
            >
                For advanced authentication features, check out our&nbsp;
                <Link
                    href="https://docs.cloud.ploomber.io/en/latest/user-guide/password.html#auth0-authentication"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="auth0-link"
                >
                    Auth0 integration.
                </Link>
            </FormLabel>
        </FormControl>
    );
}

AuthenticationController.defaultProps = {
    disabled: false,
    className: "",
    featureId: "",
    authEnabled: false,
    onCredentialsUpdate: () => {},
    onValidationChange: () => {},
    onToggle: () => {},
};

AuthenticationController.propTypes = {
    disabled: PropTypes.bool,
    onSwitchChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    featureId: PropTypes.string,
    authEnabled: PropTypes.bool,
    onCredentialsUpdate: PropTypes.func,
    onValidationChange: PropTypes.func,
    onToggle: PropTypes.func,
};

export default UserValidation(AuthenticationController);
