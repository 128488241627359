import { Menu } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledApplicationLinksMenu = styled(Menu)(({ theme }) => ({
    "& .ApplicationLinksMenuContainer": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(0.2),
        paddingBottom: theme.spacing(0.2),
        display: "grid",
    },
    "& .Headers": {
        display: "inline-flex",
        marginBottom: theme.spacing(2),
    },
    "& .ApplicationLinksMenuContainer .Row div": {
        margin: "auto 0",
    },
    "& .ApplicationLinksMenuContainer .Row div:nth-child(1)": {
        width: 100,
    },
    "& .ApplicationLinksMenuContainer .Row div:nth-child(2)": {
        width: 200,
    },
    "& .ApplicationLinksMenuContainer .DefaultController": {},
    "& .ApplicationLinksMenuContainer .ApplicationLinkController": {
        display: "inline-flex",
    },
}));

export default StyledApplicationLinksMenu;
