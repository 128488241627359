import { Alert } from "@mui/material";
import React, {
    useState,
    createContext,
    useMemo,
    useContext,
    useEffect,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import { AccountContext } from "../features/user/Account";
import { parseErrorMessage } from "../utils/utils.ts";
import StyledSnackBar from "../styles/layouts/Snackbar.styled";

const AppContext = createContext();

const emptySnackbarStatus = {
    message: "",
    severity: "info",
    vertical: "top",
    horizontal: "center",
    duration: 4000,
};

function AppContextProvider(props) {
    const { logout, getSession, authed } = useContext(AccountContext);
    const accountContext = useContext(AccountContext);

    // eslint-disable-next-line react/prop-types
    const { children } = props;
    const [user, setUser] = useState({});

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [snackbarStatus, updateSnackbarStatus] =
        useState(emptySnackbarStatus);

    const [uploadingFile, setUploadingFile] = useState({
        show: false,
        inner: {},
    });

    const navigateWithSearchParams = (to, options) => {
        const hasQueryParams = searchParams?.toString()?.length > 0;
        let navigateTo = to;

        if (hasQueryParams) {
            navigateTo += `?${searchParams.toString()}`;
        }
        navigate(navigateTo, options);
    };

    const handleCloseSnackbar = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        updateSnackbarStatus(emptySnackbarStatus);
    };

    const initUser = async () => {
        try {
            const session = await getSession();
            if (session) {
                const { email, type, onboarded } = session;
                const avatar = email[0].toUpperCase();

                setUser({
                    avatar,
                    email,
                    type,
                    onboarded,
                });
            }

            return Promise.resolve(true);
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });

            return Promise.resolve(false);
        }
    };

    const appProviderValue = useMemo(
        () => ({
            navigateWithSearchParams,
            navigate,
            location,
            searchParams,
            updateSnackbarStatus,
            setUploadingFile,
            logout,
            user,
            accountContext,
            initUser,
        }),
        [location, user]
    );

    useEffect(() => {
        if (authed) {
            initUser();
        }
    }, [authed]);

    return (
        <AppContext.Provider value={appProviderValue}>
            {uploadingFile.show && <Loader inner={uploadingFile.inner} />}

            <StyledSnackBar
                data-testid="snackbar"
                anchorOrigin={{
                    vertical: snackbarStatus.vertical || "top",
                    horizontal: snackbarStatus.horizontal || "center",
                }}
                open={Boolean(snackbarStatus.message)}
                autoHideDuration={snackbarStatus.duration}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarStatus.severity}
                >
                    {snackbarStatus.message}
                </Alert>
            </StyledSnackBar>

            {children}
        </AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };
