import React, { useContext } from "react";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { pink } from "@mui/material/colors";
import BasicBreadcrumbs from "../components/BasicBreadcrumbs";
import StyledAppBar from "../styles/layouts/AppBar.styled";
import { AppContext } from "../context/AppContext";
import { UpgradeBanner } from "../components/Pricings";

function AppBar({ activePath, open }) {
    const { user, navigate } = useContext(AppContext);

    return (
        <StyledAppBar elevation={0} position="fixed" open={open}>
            <UpgradeBanner />

            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2, display: { sm: "none" } }}
                >
                    <MenuIcon />
                </IconButton>

                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                    <BasicBreadcrumbs path={activePath} />
                </Typography>

                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <Avatar
                        className="cursor-pointer"
                        sx={{ bgcolor: pink[500] }}
                        onClick={() => {
                            navigate("/account");
                        }}
                    >
                        {user.avatar}
                    </Avatar>
                </Box>
            </Toolbar>
        </StyledAppBar>
    );
}

AppBar.propTypes = {
    activePath: PropTypes.arrayOf(PropTypes.string).isRequired,
    open: PropTypes.bool,
};

AppBar.defaultProps = {
    open: true,
};

export default AppBar;
