import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../context/AppContext";
import telemetry from "../../../services/telemetry.ts";
import VerifyEmailView from "./views/VerifyEmailView";
import JoinSlackStickyButton from "./views/components/JoinSlackStickyButton";

class VerifyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        // bind
        this.handleVerifyAccount = this.handleVerifyAccount.bind(this);
    }

    componentDidMount() {
        try {
            const appContext = this.context;
            const { location } = appContext;
            const { state } = location;
            const { testData } = this.props || {};
            const { email } = testData || state;
            this.setState({ email });
            telemetry.log(telemetry.Events.PageView, {
                includeUserDetails: true,
                email,
            });
        } catch (err) {
            window.open("/", "_self");
        }
    }

    componentDidUpdate() {}

    handleVerifyAccount() {
        const { email } = this.state;
        const appContext = this.context;
        const { navigateWithSearchParams } = appContext;

        navigateWithSearchParams("/signin", {
            state: { email },
        });
    }

    render() {
        const { email } = this.state;
        if (!email) {
            return "";
        }
        return (
            <div className="public-view-style">
                <JoinSlackStickyButton />

                <VerifyEmailView
                    onVerifyAccount={this.handleVerifyAccount}
                    email={email}
                />
            </div>
        );
    }
}

VerifyPage.contextType = AppContext;
VerifyPage.propTypes = {
    testData: PropTypes.shape({
        email: PropTypes.string,
    }),
};
VerifyPage.defaultProps = {
    testData: null,
};
export default VerifyPage;
