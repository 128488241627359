import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSidebarMenu = styled(Drawer)(({ theme }) => ({
    "& > .MuiPaper-root": {
        width: "40%",
        padding: "0 30px",
    },
    "& *": {
        fontSize: "0.75rem",
    },
}));

export default StyledSidebarMenu;
