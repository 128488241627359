import React, { useContext, useEffect, useState } from "react";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AppContext } from "../context/AppContext";
import StyledProductTour from "../styles/components/ProductTour.Styled";

function ProductTour() {
    const { user } = useContext(AppContext);
    const [run, setRun] = useState(false);

    const shouldRun = () => {
        const onboarded = localStorage.getItem("ploomber_onboarded") === "true";

        if (!onboarded && user.onboarded === false) {
            setTimeout(() => {
                setRun(true);
            }, 100);
        }
    };

    const initOnComponentReadyEvent = () => {
        document.addEventListener("onComponentReady", () => {
            setRun(false);
            setTimeout(() => {
                shouldRun();
            }, 1000);
        });
    };

    useEffect(() => {
        if ("onboarded" in user) {
            initOnComponentReadyEvent();
            shouldRun();
        }
    }, [user]);

    // todo: delete before merge
    useEffect(() => {
        document.addEventListener("runProductTour", () => {
            localStorage.removeItem("ploomber_onboarded");

            setTimeout(() => {
                setRun(true);
            }, 1000);
        });
    }, []);
    // end

    const handleOnboardingFinished = () => {
        setRun(false);
        ploomberAPI.updateOnboardingFinished();
    };

    const steps = [
        {
            target: ".open #applications",
            title: "Explore Your Applications",
            content:
                "View a list of all the applications you've deployed for easy management and monitoring.",
            disableBeacon: true,
            locale: { close: "Next" },
        },
        {
            target: "#newApplicationButton",
            title: "Create a New Application",
            content: "Quickly deploy new applications with just a few clicks.",
            disableBeacon: true,
            locale: { close: "Create new application" },
            action: () => {
                setTimeout(() => {
                    document.querySelector("#newApplicationButton")?.click();
                }, 500);
            },
        },
        {
            target: ".select-one-container",
            title: "Choose Your Framework",
            content:
                "Let's start by selecting the framework for your application.",
            disableBeacon: true,
            hideBackButton: true,
            spotlightClicks: true,
            locale: { close: "Next" },
        },
        {
            target: "#createfromexample",
            title: "Create From Example",
            content:
                "Pick from our examples to start quickly or upload your own files for customization.",
            locale: { close: "Select example" },
            disableBeacon: true,
            hideBackButton: true,
            action: () => {
                document.querySelector("#createfromexample")?.click();
            },
        },
        {
            target: "#createNewApplicationButton",
            title: "Create Your Application",
            content: "Finalize and deploy your application.",
            locale: { close: "Create application" },
            disableBeacon: true,
            hideBackButton: true,
            action: () => {
                setTimeout(() => {
                    document
                        .querySelector("#createNewApplicationButton")
                        ?.click();
                }, 500);
            },
        },
        {
            target: ".JobInfoContainer",
            title: "Application Details",
            content: "See your application framework, hardware, and more.",
            disableBeacon: true,
            hideBackButton: true,
            locale: { close: "Next" },
        },
        {
            target: ".JobStepperContainer",
            title: "Track Application Progress",
            content:
                "Monitor each step, from Docker image creation to deployment, as your application comes to life.",
            disableBeacon: true,
            locale: { close: "Next" },
        },
        {
            target: ".DefaultURLContainer",
            title: "Access Your Application",
            content:
                "Get instant access to your deployed application through the provided public link.",
            locale: { close: "Next" },
            disableBeacon: true,
        },
        {
            target: "[data-testid*='logs-tabs']",
            title: "Explore Application Logs",
            content:
                "Review detailed logs for Docker and web service activity.",
            locale: { close: "Next" },
            disableBeacon: true,
        },
        {
            target: "[data-testid='job-status-summary'] [data-testid='MoreHorizIcon']",
            title: "Manage Your Application",
            content:
                "Access settings, including the option to redeploy your application, from the convenient settings menu.",
            disableBeacon: true,
            locale: { close: "Open menu" },
            action: () => {
                document
                    .querySelector(
                        "[data-testid='job-status-summary'] [data-testid='MoreHorizIcon']"
                    )
                    ?.parentElement?.click();
                setTimeout(() => {
                    document
                        .querySelector(
                            "[data-testid*='redeploy-project-menu-item']"
                        )
                        ?.classList.add("Mui-selected");

                    handleOnboardingFinished();
                }, 100);
            },
        },
    ];

    const handleJoyrideCallback = (data) => {
        const { action, status, index, type, step } = data;

        if ([EVENTS.STEP_AFTER].includes(type)) {
            // Update state to advance the tour
            if (action === ACTIONS.CLOSE) {
                if ("action" in step) {
                    step.action();
                }
            }
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
        }
        if (action === ACTIONS.SKIP && type === EVENTS.STEP_AFTER) {
            handleOnboardingFinished();
        }
    };

    return (
        <StyledProductTour
            callback={handleJoyrideCallback}
            steps={steps}
            run={run}
        />
    );
}

export default ProductTour;
