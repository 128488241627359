import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledActionButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-sizeMedium": {
        fontSize: "0.8125rem",
        padding: "3px 9px",
    },

    "&.MuiButton-sizeSmall": {
        fontSize: "0.75rem",
        padding: "3px 9px",
    },
}));

export default StyledActionButton;
