import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import HelperTooltip from "../HelperTooltip";

function LabelsGroup({ labels, max, maxLabelSize }) {
    const numberOfLabelsToHide = labels.length - max;
    const showMoreLabels = numberOfLabelsToHide > 0;

    return (
        <HelperTooltip text={showMoreLabels > 0 ? labels.join(", ") : ""}>
            <div
                style={{
                    display: "inline-flex",
                    gap: 10,
                }}
            >
                {labels.slice(0, max).map((label) => {
                    let labelToShow = label.substr(0, maxLabelSize);
                    const hasOffset = label.length > maxLabelSize;
                    if (hasOffset) {
                        labelToShow += "...";
                    }
                    return (
                        <Chip
                            sx={{ margin: "auto" }}
                            label={labelToShow}
                            size="small"
                        />
                    );
                })}

                {showMoreLabels && (
                    <div style={{ margin: "auto" }}>
                        +{numberOfLabelsToHide}
                    </div>
                )}
            </div>
        </HelperTooltip>
    );
}

LabelsGroup.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    max: PropTypes.number.isRequired,
    maxLabelSize: PropTypes.number,
};

LabelsGroup.defaultProps = {
    maxLabelSize: 10,
};

export default LabelsGroup;
