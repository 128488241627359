interface Options {
    method: string;

    headers?: Headers;

    body?: any;
}

interface HTTPRequest {
    headers?: Headers;
    body?: any;
}

const prepareRequestOptions = (
    method: string,
    headers?: Headers,
    body?: any
): Options => {
    const options: Options = {
        method,
    };

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    return options;
};

const handleError = async (response: Response) => {
    let error = "";
    const text = await response.text();
    try {
        const data = JSON.parse(text);
        error = data;
    } catch (err) {
        error = text;
    }

    return Promise.resolve(error);
};

const post = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<any> => {
    const postOptions = prepareRequestOptions("POST", headers, body);

    try {
        const response = await fetch(url, postOptions);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const put = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<any> => {
    const options = prepareRequestOptions("PUT", headers, body);

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const patch = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<any> => {
    const options = prepareRequestOptions("PATCH", headers, body);

    if (headers) {
        options.headers = headers;
    }

    if (body) {
        options.body = body;
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const get = async (url: string, headers?: Headers): Promise<any> => {
    const options = prepareRequestOptions("GET", headers);

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

const del = async (
    url: string,
    { headers, body }: HTTPRequest
): Promise<any> => {
    const postOptions = prepareRequestOptions("DELETE", headers, body);

    try {
        const response = await fetch(url, postOptions);

        if (!response.ok) {
            throw await handleError(response);
        }

        return Promise.resolve(response);
    } catch (err) {
        return Promise.reject(err);
    }
};

export default { post, put, get, del, patch };
