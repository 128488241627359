import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import * as uuid from "uuid";
import StyledTerminal from "../styles/components/Terminal.Styled";

function Terminal({ text, splitTextFunction, id, placeholder }) {
    const terminalRef = useRef(null);
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

    let rows = [];

    // Check anytime there is an update to a new text input or autoScroll has been updated
    useEffect(() => {
        if (shouldAutoScroll && terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [text, shouldAutoScroll]);

    const handleScroll = () => {
        // Check if the user is at the bottom
        const { scrollTop, scrollHeight, clientHeight } = terminalRef.current;
        const userAtBottom = scrollTop + clientHeight >= scrollHeight;

        // Enable or disable auto-scroll based on user position
        setShouldAutoScroll(userAtBottom);
    };

    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.addEventListener("scroll", handleScroll);
        }

        // Unmount event handler once the component is removed
        return () => {
            if (terminalRef.current) {
                terminalRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    if (splitTextFunction) {
        rows = splitTextFunction(text);
    } else if (text !== "") {
        rows = text.split("\n");
    }
    return (
        <StyledTerminal
            ref={terminalRef}
            id={id}
            data-testid={`terminal-${id}`}
        >
            {rows?.length > 0
                ? rows.map((row, i) => (
                      <div
                          key={i}
                          className="Row"
                          style={{ overflowWrap: "anywhere" }}
                      >
                          {row}
                      </div>
                  ))
                : placeholder}
        </StyledTerminal>
    );
}

Terminal.propTypes = {
    text: PropTypes.string,
    splitTextFunction: PropTypes.func,
    id: PropTypes.string,
    placeholder: PropTypes.string,
};

Terminal.defaultProps = {
    text: "",
    splitTextFunction: undefined,
    id: uuid.v4(),
    placeholder: "",
};

export default Terminal;
