import React, { useEffect, useContext } from "react";
import telemetry from "../../../services/telemetry.ts";
import { SigninView } from "./views";
import JoinSlackStickyButton from "./views/components/JoinSlackStickyButton";
import { AppContext } from "../../../context/AppContext";
import { parseErrorMessage } from "../../../utils/utils.ts";

function SigninPage(props) {
    const { navigate, location, updateSnackbarStatus } = useContext(AppContext);

    useEffect(() => {
        telemetry.log(telemetry.Events.PageView, { includeUserDetails: false });
    }, []);

    const handleSignIn = () => {
        const returnUrl =
            new URLSearchParams(location.search).get("returnUrl") || "/";
        navigate(returnUrl);
    };

    return (
        <div className="public-view-style">
            <JoinSlackStickyButton />

            <SigninView
                onSuccess={handleSignIn}
                onFailure={(err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                }}
            />
        </div>
    );
}

export default SigninPage;
