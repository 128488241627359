import React, { useContext } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { AppContext } from "../../../../context/AppContext";
import { getCustomDomainStatusChipColor } from "../../../../utils/uiUtils.ts";
import StyledChip from "../../../../styles/components/Chip.Styled";
import CustomDomainContext from "./CustomDomainContext";

function ShowDNSInstructionsForPointingMethod() {
    const { domain } = useContext(CustomDomainContext);

    const { updateSnackbarStatus } = useContext(AppContext);

    const handleCopy = (value) => {
        navigator.clipboard.writeText(value);
        updateSnackbarStatus({
            message: `${value} copied to clipboard`,
        });
    };

    return (
        <Box>
            <Box className="DNSTableContainer">
                {domain.dns_records.length > 0 ? (
                    <>
                        <h1>
                            Add the following DNS records to your domain host
                            account
                        </h1>

                        <TableContainer component={Paper}>
                            <Table size="small" className="DNSTable DNSRecords">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Host</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {domain.dns_records.map((item) => (
                                        <TableRow key={item.type}>
                                            <TableCell scope="row">
                                                {item.type}
                                            </TableCell>
                                            <TableCell>{item.host}</TableCell>
                                            <TableCell>
                                                <div>
                                                    {item.value}
                                                    <Button
                                                        onClick={() => {
                                                            handleCopy(
                                                                item.value
                                                            );
                                                        }}
                                                        size="small"
                                                        variant="outline"
                                                        startIcon={
                                                            <ContentCopyIcon />
                                                        }
                                                    >
                                                        Copy
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <div>
                        <h2>This domain is not attached to any application</h2>
                    </div>
                )}
            </Box>

            <Box className="DNSTableContainer">
                <h1>SSL certification status</h1>
                <p>
                    In order to make this domain available via https, please add
                    the following CNAME record and verify domain ownership.
                </p>

                <TableContainer
                    component={Paper}
                    className="DNSTable CertificateTable"
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Domain</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>CNAME name</TableCell>
                                <TableCell>CNAME value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">{domain.name}</TableCell>
                                <TableCell scope="row">
                                    <StyledChip
                                        className="Capitalize"
                                        label={domain.certificate.status}
                                        color={getCustomDomainStatusChipColor(
                                            domain.certificate.status
                                        )}
                                        size="small"
                                    />
                                </TableCell>

                                <TableCell scope="row">CNAME</TableCell>
                                <TableCell className="CellWithTextAndAction">
                                    <div>
                                        <div className="Text">
                                            {domain.certificate.name}
                                        </div>

                                        <div className="Action">
                                            <Button
                                                onClick={() => {
                                                    handleCopy(
                                                        domain.certificate.name
                                                    );
                                                }}
                                                size="small"
                                                variant="outline"
                                                startIcon={<ContentCopyIcon />}
                                            >
                                                Copy
                                            </Button>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className="CellWithTextAndAction">
                                    <div>
                                        <div className="Text">
                                            {domain.certificate.value}
                                        </div>
                                        <div className="Action">
                                            <Button
                                                onClick={() => {
                                                    handleCopy(
                                                        domain.certificate.value
                                                    );
                                                }}
                                                size="small"
                                                variant="outline"
                                                startIcon={<ContentCopyIcon />}
                                            >
                                                Copy
                                            </Button>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box className="Notes">
                    <h3>Notes:</h3>
                    <Box>
                        Certificate processing time varies (up to 72 hours),
                        depending on your SSL provider.
                    </Box>
                    <Box>
                        When the certificate is ready, the status will be
                        changed to{" "}
                        <span className="font-style-italic">ISSUED</span>.
                    </Box>
                    <Box>
                        If the CNAME record is not added within 72 hours, the
                        status will be marked as{" "}
                        <span className="font-style-italic">
                            VALIDATION_TIMED_OUT
                        </span>{" "}
                        status.
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ShowDNSInstructionsForPointingMethod;
