export enum JobStatus {
    NOT_DEPLOYED = "not-deployed",
    CREATED = "created",
    PENDING = "pending",
    ACTIVE = "active",
    INACTIVE = "inactive",
    RUNNING = "running",
    STOPPING = "stopping",
    STOPPED = "stopped",
    FINISHED = "finished",
    FAILED = "failed",
    BUILD_DOCKER = "build-docker",
    DOCKER_FAILED = "docker-failed",
    DOCKER_BUILD_COMPLETE = "docker-build-complete",
    PROVISIONING = "provisioning",
    PROVISIONING_FAILED = "provisioning-failed",
    INFRASTRUCTURE_FAILED = "infrastructure-failed",
    WEBSERVICE_ACTIVE = "waiting-for-traffic",
    WEBSERVICE_PENDING = "deploy-service",
}

export const UserType = {
    COMMUNITY: { value: "community", label: "Community" },
    PRO: { value: "pro", label: "Professional" },
    ADMIN: { value: "admin", label: "Admin" },
    TEAMS: { value: "teams", label: "Teams" },
};

export enum CertificateStatus {
    PENDING_VALIDATION = "PENDING_VALIDATION",
    ISSUED = "ISSUED",
    INACTIVE = "INACTIVE",
    EXPIRED = "EXPIRED",
    VALIDATION_TIMED_OUT = "VALIDATION_TIMED_OUT",
    REVOKED = "REVOKED",
    FAILED = "FAILED",
}
