import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { AccountContext } from "../features/user/Account";
import { AppContext } from "../context/AppContext";

function SocialLogin({ onSuccess, onFailure }) {
    const accountContext = useContext(AccountContext);
    const { searchParams } = useContext(AppContext);
    const { createSessionWithToken } = accountContext;

    useEffect(() => {
        // On DEV env this event fires multiple times (<React.StrictMode></React.StrictMode>)
        window.addEventListener(
            "message",
            async (event) => {
                const validOrigin = true; // event.origin === window.location.origin;
                if (validOrigin) {
                    const { location } = event.source;
                    const { pathname } = location;
                    if (
                        pathname === "/authorize_login" ||
                        pathname === "/application"
                    ) {
                        const { data } = event.data;
                        createSessionWithToken(data);
                        window.location = "/";
                        // onSuccess();
                    }
                }
            },
            false
        );
    }, []);

    const openSocialSigninWindow = (url) => {
        window.open(url, "_self");
    };

    const signinWithSocial = (connection) => {
        // Generate state variable and store in localstorage to prevent CSRF
        // Ref: Authentication Flow - https://github.com/ploomber/cloud-frontend/issues/103#issuecomment-1731992383
        // Ref: State Param - https://auth0.com/docs/secure/attack-protection/state-parameters
        const generateState = () => {
            const characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let result = "";

            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(
                    Math.random() * characters.length
                );
                result += characters.charAt(randomIndex);
            }

            localStorage.setItem("socialLoginState", result);
            return result;
        };

        const state = generateState();
        const redirectURI = `${window.location.protocol}//${window.location.host}/social-login-proxy/`;
        const url = `${process.env.REACT_APP_AUTH0_URI}/authorize?response_type=code&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&connection=${connection}&redirect_uri=${redirectURI}&scope=openid+profile+email+offline_access&state=${state}&audience=publicAPI`;
        openSocialSigninWindow(url);
    };
    return (
        <div className="other-sign">
            {/* TODO: Change to icons with colors */}
            <div id="googlesignin">
                <div
                    className="other-sign-list"
                    id="google-login-button"
                    role="button"
                    onKeyPress={(e) => {}}
                    tabIndex="0"
                    onClick={() => {
                        signinWithSocial("google-oauth2");
                    }}
                >
                    <i
                        id="google-logo"
                        className="fab fa-google social-login-icon"
                    />
                    <span className="social-login-text">
                        Sign in with Google
                    </span>
                </div>
            </div>
            <div
                className="other-sign-list"
                id="linkedin-login-button"
                role="button"
                onKeyPress={(e) => {}}
                tabIndex="0"
                onClick={() => {
                    signinWithSocial("linkedin");
                }}
            >
                <i
                    id="linkedin-logo"
                    className="fab fa-linkedin social-login-icon"
                />
                <span className="social-login-text">Sign in with Linkedin</span>
            </div>
            <div
                className="other-sign-list"
                id="github-login-button"
                role="button"
                onKeyPress={(e) => {}}
                tabIndex="0"
                onClick={() => {
                    signinWithSocial("github");
                }}
            >
                <i
                    id="github-logo"
                    className="fab fa-github social-login-icon"
                />
                <span className="social-login-text">Sign in with GitHub</span>
            </div>
            {/* https://www.youtube.com/watch?v=r1P_glQGvfo */}
            {/* https://www.youtube.com/watch?v=_rFJkM3uhtA */}
            {/* https://www.youtube.com/watch?v=xnmi0oc19e4 */}
        </div>
    );
}

SocialLogin.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default SocialLogin;
