import { styled } from "@mui/material/styles";

const LoadState = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
};

const NotebookPageHeader = styled("div")({
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginLeft: "20px",
});

const NotebookPageLogo = styled("img")({
    width: "140px",
    height: "auto",
});

const NotebookPageContainer = styled("div")({
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
});

const NotebookPageFooter = styled("div")({
    textAlign: "center",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
});

const NotebookPageStatusContainer = styled("div")({
    textAlign: "center",
    margin: "40px 20px",
});

const NotebookPageText = styled("div")({
    textAlign: "center",
});

export {
    LoadState,
    NotebookPageContainer,
    NotebookPageFooter,
    NotebookPageHeader,
    NotebookPageLogo,
    NotebookPageStatusContainer,
    NotebookPageText,
};
