import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPricingsDialog = styled(Dialog)(({ theme }) => ({
    color: theme.palette.neutral.black,
    fontSize: "1rem",
    textAlign: "center",
    "& .PageTitle": {
        textAlign: "center",
    },
    "& .PlansContainer": {
        margin: "auto",
        marginTop: theme.spacing(10),
        display: "grid",
        gridTemplateColumns: "auto auto auto auto",
        gap: theme.spacing(2),
    },
    ".PricingPlan": {
        border: `1px solid ${theme.palette.neutral.grey}`,
        borderRadius: "1.5rem",
        padding: theme.spacing(1.5),
        width: 300,
    },
    ".PricingPlan.Selected": {
        border: "1px solid #687382",
    },
    ".PricingPlan.Selected .Badge": {
        backgroundColor: "#687382",
        width: 200,
        margin: "auto",
        color: "#fff",
        marginTop: -27,
        border: "3px solid #fff",
        borderRadius: "1rem",
    },
    ".PricingPlan .Title": {
        marginTop: 20,
        fontWeight: 700,
        height: 50,
    },
    ".PricingPlan .PriceContainer > div": {
        height: 150,
        margin: "0 auto",
    },
    ".PricingPlan .PriceContainer .Small": {
        fontSize: "1rem",
        color: "#64748b",
    },
    ".PricingPlan .PriceContainer .Price": {
        fontSize: "2.5rem",
        fontWeight: 600,
    },
    ".PricingPlan .PriceContainer .CustomPricing": {
        fontSize: "2.5rem",
    },
    ".PricingPlan .Features": {
        textAlign: "left",
        height: 300,
    },
    ".PricingPlan .Features .Feature": {
        display: "inline-flex",
    },
    ".PricingPlan .Features .Feature .Icon": {
        margin: "auto 10px",
    },
    ".PricingPlan .Features .Feature .Icon *": {
        color: "#F9C32B",
        fontSize: "1.2rem",
    },
    ".PricingPlan [id*='upgradeButton']": {
        backgroundColor: "#f9c32b",
        width: "100%",
        height: 50,
        color: "#000",
    },
    ".PricingPlan.Selected [id*='upgradeButton']": {
        backgroundColor: theme.palette.neutral.grey,
        color: theme.palette.neutral.black,
    },
    "& .MuiDialogTitle-root": {
        display: "flex",
        alignItems: "center",
    },
    "& .MuiDialogTitle-root .CloseIcon": {
        marginLeft: "auto",
    },
}));

export default StyledPricingsDialog;
