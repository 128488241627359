import React from "react";
import { Outlet } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import InfoIcon from "@mui/icons-material/Info";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ArticleIcon from "@mui/icons-material/Article";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ExploreIcon from "@mui/icons-material/Explore";
import AppBar from "../../../layouts/AppBar";
import Sidebar from "../../../layouts/Sidebar";
import { AppContext } from "../../../context/AppContext";
import telemetry from "../../../services/telemetry.ts";

const shouldOpenDrawer = (pathParts) => {
    // TODO: Create a better mechanism to control the state of the sidebar
    const isCreate = pathParts.length === 2 && pathParts[1] === "create";
    const isApplicationStatus =
        pathParts.length === 3 && pathParts[0] === "applications";
    const isApplicationSettings =
        pathParts.length === 4 &&
        pathParts[0] === "applications" &&
        pathParts[3] === "settings";
    return !isCreate && !isApplicationStatus && !isApplicationSettings;
};

class AuthorizedPage extends React.Component {
    constructor(props) {
        super(props);

        const sidebarLinks = [
            {
                id: "applications",
                to: "/applications",
                icon: <DashboardCustomizeRoundedIcon height={20} />,
            },
            {
                id: "notebooks",
                to: "/notebooks",
                icon: <LibraryBooksIcon />,
            },
            {
                id: "account",
                to: "/account",
                icon: <PersonIcon />,
            },
        ];

        const sidebarBottomLinks = [
            {
                id: "pricing",
                icon: <InfoIcon />,
                action: () => {
                    window.open(
                        "https://docs.cloud.ploomber.io/en/latest/pricing/overview.html"
                    );
                },
            },
            {
                id: "explore",
                icon: <ExploreIcon />,
                action: async () => {
                    window.open(
                        `${process.env.REACT_APP_URL}/explore`,
                        "_blank"
                    );
                },
            },
            {
                id: "documentation",
                icon: <ArticleIcon />,
                action: () => {
                    window.open("https://docs.cloud.ploomber.io");
                },
            },
            {
                id: "Get Support",
                icon: <HelpCenterIcon />,
                action: () => {
                    window.open(
                        "https://docs.cloud.ploomber.io/en/latest/quickstart/support.html"
                    );
                },
            },
            {
                id: "logout",
                icon: <LogoutIcon />,
                action: () => {
                    const appContext = this.context;
                    const { navigate, logout } = appContext;
                    telemetry.log("Logout");

                    logout();
                    navigate("/");
                },
            },
        ];

        this.state = {
            sidebarLinks,
            sidebarBottomLinks,
            activePath: [],
            openDrawer: false,
        };
    }

    componentDidMount() {
        this.updateActivePath();
    }

    componentDidUpdate() {
        this.updateActivePath();
    }

    updateActivePath() {
        const appContext = this.context;
        const { location } = appContext;
        const { pathname } = location;
        const newActivePath = pathname.split("/").filter((s) => s);
        const { activePath } = this.state;
        const shouldUpdate =
            JSON.stringify(newActivePath) !== JSON.stringify(activePath);
        const openDrawer = shouldOpenDrawer(newActivePath);

        if (shouldUpdate) {
            this.setState({
                activePath: newActivePath,
                openDrawer,
            });
        }
    }

    render() {
        const { activePath, openDrawer, sidebarLinks, sidebarBottomLinks } =
            this.state;

        return (
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar activePath={activePath} open={openDrawer} />
                <Sidebar
                    links={sidebarLinks}
                    bottomLinks={sidebarBottomLinks}
                    active={activePath}
                    open={openDrawer}
                />

                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    {/* Render nested route component */}
                    <Outlet />
                </Box>
            </Box>
        );
    }
}

AuthorizedPage.contextType = AppContext;

export default AuthorizedPage;
