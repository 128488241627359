import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledUpgradeBanner = styled(Box)(({ theme, hide }) => ({
    "&": {
        width: "100%",
        backgroundColor: "#fbe7c4",
        top: 0,
        left: 0,
        position: "relative",
        padding: theme.spacing(0.5),
        display: hide ? "none" : "visible",
        textAlign: "center",
        color: "#000",
    },
    "& .UpgradeBannerContainer": {
        display: "inline-flex",
        width: "100%",
    },
    "& .UpgradeBannerCenterTextContainer": {
        margin: "auto",
        flexGrow: "1",
        display: "inline-flex",
        justifyContent: "center",
    },
    "& .CloseUpgradeBannerContainer": {
        marginRight: theme.spacing(2),
    },
    "& .CloseUpgradeBannerContainer .MuiIconButton-root svg": {
        fontSize: "medium",
    },

    "& .UpgradeButton": {
        cursor: "pointer",
        textDecoration: "underline",
    },
}));

export default StyledUpgradeBanner;
