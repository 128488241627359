import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Link,
} from "@mui/material";
import PropTypes from "prop-types";

import "./SelectOneTable.css";

const voilaExamples = [
    {
        id: "voila-chat-with-csv",
        title: "Chat with a CSV file",
        subtitle: "Use OpenAI, JupySQL and DuckDB to chat with a CSV file",
        sourceLink:
            "https://github.com/ploomber/doc/tree/main/examples/voila/chat-with-csv",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/voila/chat-with-csv",
    },
    {
        id: "voila-mosaic",
        title: "Interactive data visualization",
        subtitle: "Use Mosaic to create an interactive data visualization",
        sourceLink:
            "https://github.com/ploomber/doc/tree/main/examples/voila/mosaic",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/voila/mosaic",
    },
    {
        id: "voila-jupysql",
        title: "Analytical application",
        subtitle: "Use JupySQL and DuckDB to plot data",
        sourceLink:
            "https://github.com/ploomber/doc/tree/main/examples/voila/jupysql",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/voila/jupysql",
    },
    {
        id: "voila-ml",
        title: "Machine Learning",
        subtitle:
            "Use sklearn-evaluation to visualize a Machine Learning model",
        sourceLink:
            "https://github.com/ploomber/doc/tree/main/examples/voila/ml",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/voila/ml",
    },
];

const dockerExamples = [
    {
        id: "docker-fastapi",
        title: "FastAPI application",
        subtitle: "Sample FastAPI application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/fastapi/basic-app/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/fastapi/basic-app/app.zip",
    },
    {
        id: "docker-llama-gpu",
        title: "A GPU webserver with Llama 2",
        subtitle: "This starts a webserver allowing to chat with LLMs",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/docker/llama-cpp-server/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/docker/llama-cpp-server/app.zip",
    },
    {
        id: "docker-fastapi-gpu",
        title: "A GPU FastAPI with moondream2",
        subtitle:
            "This application allows you to ask questions about uploaded images",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/fastapi/describe-image-backend/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/fastapi/describe-image-backend/app.zip",
    },
];

const panelExamples = [
    {
        id: "panel-data-viz",
        title: "Data visualization",
        subtitle: "Sample data visualization application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/panel/data-viz/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/panel/data-viz/app.zip",
    },
];

const solaraExamples = [
    {
        id: "solara-data-viz",
        title: "GPT-4-Tokenizer",
        subtitle: "Use Pandas to understand tokenization on GPT-4",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/solara/gpt-4-tokenizer/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/solara/gpt-4-tokenizer/app.zip",
    },
];

const streamlitExamples = [
    {
        id: "streamlit-data-viz",
        title: "Data visualization",
        subtitle: "Sample data visualization application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/streamlit/data-viz/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/streamlit/data-viz/app.zip",
    },
];

const shinyRExamples = [
    {
        id: "shiny-r-data-viz",
        title: "Data visualization",
        subtitle: "Sample data visualization application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/shiny-r/shiny-r-data-visualization/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/shiny-r/shiny-r-data-visualization/app.zip",
    },
];

const dashExamples = [
    {
        id: "dash-data-viz",
        title: "Data visualization",
        subtitle: "Sample data visualization application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/dash/simple-app/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/dash/simple-app/app.zip",
    },
];

const flaskExamples = [
    {
        id: "flask-basic-app",
        title: "Basic application",
        subtitle: "Sample flask application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/flask/basic-app/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/flask/basic-app/app.zip",
    },
];

const chainlitExamples = [
    {
        id: "chainlit-basic-app",
        title: "Basic application",
        subtitle: "Sample chainlit application",
        sourceLink:
            "https://github.com/ploomber/doc/blob/main/examples/chainlit/basic-app/app.zip",
        rawLink:
            "https://raw.githubusercontent.com/ploomber/doc/main/examples/chainlit/basic-app/app.zip",
    },
];

let currentExamples = voilaExamples;

const examplesMap = new Map([
    ["voila", voilaExamples],
    ["docker", dockerExamples],
    ["panel", panelExamples],
    ["streamlit", streamlitExamples],
    ["solara", solaraExamples],
    ["shiny-r", shinyRExamples],
    ["dash", dashExamples],
    ["flask", flaskExamples],
    ["chainlit", chainlitExamples],
]);

/* get the examples for a given framework identifier
 */
function examplesForFramework(framework) {
    return examplesMap.get(framework) || [];
}

/**
 * Table that allows selecting one option, currently used for the examples
 * @param {string} selectedOption - The currently selected option
 * @param {function} onOptionSelect - Callback to execute when an option is selected
 * @returns {JSX.Element}
 */
const SelectOneTable = function ({
    selectedOption,
    onOptionSelect,
    selectedFramework,
}) {
    const handleOptionSelect = (option) => {
        onOptionSelect(option);
    };

    currentExamples = examplesForFramework(selectedFramework);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {currentExamples.map((option) => (
                        <TableRow
                            key={option.id}
                            onClick={() => handleOptionSelect(option)}
                            className={
                                selectedOption === option.id
                                    ? "selected-row"
                                    : "non-selected-row"
                            }
                            id={`example-${option.id}`}
                        >
                            <TableCell>
                                <strong>{option.title}</strong>
                            </TableCell>
                            <TableCell>{option.subtitle}</TableCell>
                            <TableCell>
                                <span />
                            </TableCell>
                            <TableCell>
                                {selectedFramework === "voila" ? (
                                    <Link
                                        href={option.sourceLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View source
                                    </Link>
                                ) : (
                                    <Link
                                        href={option.rawLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Download source
                                    </Link>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

SelectOneTable.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    selectedFramework: PropTypes.string.isRequired,
};

export { SelectOneTable, examplesForFramework };
