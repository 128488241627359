import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import telemetry from "../../../services/telemetry.ts";
import { PasswordRecoveryView } from "./views";
import JoinSlackStickyButton from "./views/components/JoinSlackStickyButton";
import { parseErrorMessage } from "../../../utils/utils.ts";

function PasswordRecoveryPage(props) {
    const { navigate, updateSnackbarStatus } = useContext(AppContext);

    return (
        <div className="public-view-style">
            <JoinSlackStickyButton />

            <PasswordRecoveryView
                onSuccess={(email) => {
                    updateSnackbarStatus({
                        message:
                            "Check your mailbox for the link to reset the password",
                    });
                    telemetry.log(
                        `User ${email} requested for reset the password`
                    );
                }}
                onFailure={(err) => {
                    updateSnackbarStatus({
                        message: parseErrorMessage(err),
                        severity: "error",
                    });
                }}
            />
        </div>
    );
}

export default PasswordRecoveryPage;
